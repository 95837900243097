<template>
  <div>
    <div class="userinfo anglebox buble-bg">
      <div @click="jump(item.path)" v-for="item in list" :key="item.name">
        <div
          class="flex align-center p-16 text-left buble-line"
          v-if="item.show"
        >
          <div class="mr-12">
            <img :src="item.url" alt="" class="item-img" />
          </div>
          <div class="user">
            <div class="mb-2">{{ item.name }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: "ChoiceETF",
  emits: ["choice"],
  props: {
    etf_spot: Boolean,
    etf_lever: Boolean,
    etf_second_lever: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const state = reactive({
      tabData: [
        {
          name: t("ETFCommodity.etfSpot"),
          path: "/etf",
          show: props.etf_spot,
          desc: t("ETFCommodity.etfSpotTip"),
          url: require("@/assets/theme1/header/ETF.png"),
        },
        {
          name: t("ETFCommodity.etfLever"),
          path: "/etfLever",
          show: props.etf_lever,
          desc: t("ETFCommodity.etfLeverTip"),
          url: require("@/assets/theme1/header/ETFlever.png"),
        },
        {
          name: t("ETFCommodity.etfSecond"),
          path: "/etfSecond",
          show: props.etf_second_lever,
          desc: t("ETFCommodity.etfSecondTip"),
          url: require("@/assets/theme1/header/ETFsecond.png"),
        },
      ],
    });
    const list = state.tabData;
    const $router = useRouter();
    const jump = (path) => {
      console.log(path, "-----------------");
      $router.push(path);
    };
    return {
      state,
      jump,
      list,
    };
  },
};
</script>

<style lang="less" scoped>
// 个人中心弹窗
.p-10 {
  padding-top: 10px;
}
.userinfo {
  width: 300px;
  position: relative;
  // border-radius: 8px;
  // padding: 0 10px;
}
.user {
  // height: 44px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
}
.p-16 {
  padding: 16px;
}
.desc {
  font-size: 10px;
  line-height: 14px;
  color: #ffff;
  opacity: 0.5;
  font-weight: normal;
}
.mb-2 {
  margin-bottom: 2px;
}
.mr-12 {
  margin-inline-end: 12px;
}
.item-img {
  display: block;
  width: 30px;
  height: 30px;
}
.name {
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-inline-start: 6px;
}
.center-model {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.mb-8 {
  margin-bottom: 8px;
}
.logout {
  height: 37px;
  line-height: 37px;
  color: #cf2f43;
}
</style>
