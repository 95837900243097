import request from "../utils/request";

// ETF交易对
/**
 * 自选/取消自选
 */
export function etfCollect(params) {
  return request({
    url: "/v1/etf/collect",
    method: "GET",
    params,
  });
}

/**
 * 置顶/取消置顶
 */
export function etfCollectTop(params) {
  return request({
    url: "/v1/etf/collect/top",
    method: "GET",
    params,
  });
}

/**
 * 列表
 */
export function etfList(params) {
  return request({
    url: "/v1/etf/list",
    method: "GET",
    params,
  });
}

/**
 * 详情
 */
export function etfDetail(type, id) {
  return request({
    url: `/v1/etf/${type}/${id}`,
    method: "GET",
  });
}

//ETF合约

/**
 *合约交易撤单
 * @returns
 */
export function OrderEtfLeverCancelOrder(id) {
  return request({
    url: `/v1/OrderEtfLever/cancelOrder/${id}`,
    method: "GET",
  });
}
/**
 *合约交易订单平仓
 * @returns
 */
export function OrderEtfLeverClose(id) {
  return request({
    url: `/v1/OrderEtfLever/close/${id}`,
    method: "GET",
  });
}
/**
 *合约交易订单详情
 * @returns
 */
export function OrderEtfLeverDetails(id) {
  return request({
    url: `/v1/OrderEtfLever/details/${id}`,
    method: "GET",
  });
}
/**
 *合约费率列表
 * @returns
 */
export function OrderEtfLeverFeeList() {
  return request({
    url: `/v1/OrderEtfLever/leverFeeList`,
    method: "GET",
  });
}
/**
 *合约下单信息
 * @returns
 */
export function OrderEtfLeverInfo(etf_id) {
  return request({
    url: `/v1/OrderEtfLever/leverInfo/${etf_id}`,
    method: "GET",
  });
}
/**
 * ETF合约交易下单
 * @param {*} data
 * @returns
 */
export function OrderEtfLeverMakeOrder(data) {
  return request({
    url: "/v1/OrderEtfLever/leverOrder",
    method: "POST",
    data,
  });
}
/**
 * ETF合约交易记录
 * @param {*} params
 * @returns
 */
export function OrderEtfLeverOrderPage(params) {
  return request({
    url: "/v1/OrderEtfLever/leverPage",
    method: "GET",
    params: params,
  });
}
/**
 * ETF合约当前委托
 * @param {*} params
 * @returns
 */
export function OrderEtfLeverPendingPage(params) {
  return request({
    url: "/v1/OrderEtfLever/pendingPage",
    method: "GET",
    params: params,
  });
}
/**
 * ETF合约合约持仓
 * @param {*} params
 * @returns
 */
export function OrderEtfLeverPositionPage(params) {
  return request({
    url: "/v1/OrderEtfLever/positionPage",
    method: "GET",
    params: params,
  });
}
/**
 *开通ETF合约账户
 * @param {
 * } params
 * @returns
 */
export function OrderEtfLeverOpenLever() {
  return request({
    url: "/v1/OrderEtfLever/openLever",
    method: "GET",
  });
}
/**
 *设置止盈止损
 * @param {
 * } data
 * @returns
 */
export function OrderEtfLeverSetLeverProfit(data) {
  return request({
    url: "/v1/OrderEtfLever/setLeverProfit",
    method: "POST",
    data,
  });
}

//ETF期权
/**
 *ETF期权取消订单
 * @returns
 */
export function OrderEtfSecondLeverCancelOrder(id) {
  return request({
    url: `/v1/OrderEtfSecondLever/cancelOrder/${id}`,
    method: "GET",
  });
}
/**
 *ETF期权详情
 * @returns
 */
export function OrderEtfSecondLeverDetails(id) {
  return request({
    url: `/v1/OrderEtfSecondLever/details/${id}`,
    method: "GET",
  });
}
/**
 *ETF期权费率列表
 * @returns
 */
export function OrderEtfSecondLeverFeeList() {
  return request({
    url: `/v1/OrderEtfSecondLever/handlingFeeList`,
    method: "GET",
  });
}
/**
 *ETF期权下单信息
 * @returns
 */
export function OrderEtfSecondLeverInfo(etf_id) {
  return request({
    url: `/v1/OrderEtfSecondLever/orderSecondLeverInfo/${etf_id}`,
    method: "GET",
  });
}
/**
 * ETF期权交易下单
 * @param {*} data
 * @returns
 */
export function OrderEtfSecondLeverMakeOrder(data) {
  return request({
    url: "/v1/OrderEtfSecondLever/orderSecondLever",
    method: "POST",
    data,
  });
}
/**
 * ETF期权当前委托
 * @param {*} params
 * @returns
 */
export function OrderEtfSecondLeverPendingPage(params) {
  return request({
    url: "/v1/OrderEtfSecondLever/pendingPage",
    method: "GET",
    params: params,
  });
}

/**
 * ETF期权当前持仓
 * @param {*} params
 * @returns
 */
export function OrderEtfSecondLeverPositionPage(params) {
  return request({
    url: "/v1/OrderEtfSecondLever/positionPage",
    method: "GET",
    params: params,
  });
}
/**
 * ETF期权交易记录
 * @param {*} params
 * @returns
 */
export function OrderEtfSecondLeverOrderPage(params) {
  return request({
    url: "/v1/OrderEtfSecondLever/recordPage",
    method: "GET",
    params: params,
  });
}

// ETF现货

//ETF账户信息
export function orderEtfAccount(params) {
  return request({
    url: "/v1/orderEtf/account",
    method: "GET",
    params: params,
  });
}
//ETF现货交易撤单
export function orderEtfCancelOrder(id) {
  return request({
    url: `/v1/orderEtf/cancelOrder/${id}`,
    method: "GET",
  });
}
//ETF现货下单
export function orderEtfMakeOrder(data) {
  return request({
    url: "/v1/orderEtf/makeOrder",
    method: "POST",
    data,
  });
}

//ETF现货当前委托列表
export function orderEtfPendingPage(params) {
  return request({
    url: "/v1/orderEtf/pendingPage",
    method: "GET",
    params: params,
  });
}

//ETF现货当前持仓列表
export function orderEtfPositionPage(params) {
  return request({
    url: "/v1/orderEtf/positionPage",
    method: "GET",
    params: params,
  });
}

//ETF现货持仓记录
export function orderEtfPositionRecordPage(params) {
  return request({
    url: "/v1/orderEtf/positionRecordPage",
    method: "GET",
    params: params,
  });
}

//ETF现货交易记录
export function orderEtfRecordPage(params) {
  return request({
    url: "/v1/orderEtf/recordPage",
    method: "GET",
    params: params,
  });
}

/**
 *ETF现货费率列表
 * @returns
 */
export function spotFeeList() {
  return request({
    url: `/v1/orderEtf/spotFeeList`,
    method: "GET",
  });
}

//ETFK线行情分页列表
export function etfQuotationKlinePage(params) {
  return request({
    url: "/v1/etfQuotation/kline/page",
    method: "GET",
    params: params,
  });
}

//K线行情列表(时间段)
export function etfQuotationSeoPage(params) {
  return request({
    url: "/v1/etfQuotation/seo/list",
    method: "GET",
    params: params,
  });
}

/**
 *ETF现货盘口数据
 * @param {
 * } params
 * @returns
 */
export function etfQuotationSpot(id) {
  return request({
    url: `/v1/etfQuotation/spot/${id}`,
    method: "GET",
  });
}

/**
 *ETF合约盘口数据
 * @param {
 * } params
 * @returns
 */
export function etfQuotationLever(id) {
  return request({
    url: `/v1/etfQuotation/lever/${id}`,
    method: "GET",
  });
}
