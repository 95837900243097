const commodityRouter = [
  {
    path: "/:lang/commodity",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Commodity",
    component: () => import("@/views/commodity/commodity.vue"),
  },
  {
    path: "/:lang/commodityLever",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "CommodityLever",
    component: () => import("@/views/commodity/commodityLever.vue"),
  },
  {
    path: "/:lang/commoditySecond",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "CommoditySecond",
    component: () => import("@/views/commodity/commoditySecond.vue"),
  },
];
export default commodityRouter;
