<template>
  <div>
    <div
      :class="
        isLoginReg
          ? theme == 0
            ? 'header transparent'
            : 'header transparent-theme'
          : 'header head-bg'
      "
    >
      <div class="header-left point align-center">
        <!-- <img @click="backhome" src="@/assets/images/logo.png" alt /> -->
        <img
          @click="backhome"
          src="@/assets/theme1/logo.png"
          alt
          v-if="!isLoginReg"
        />
        <img
          @click="backhome"
          src="@/assets/theme1/logo.png"
          alt
          v-if="isLoginReg"
        />
        <div class="titletab share-color flex js-st">
          <div
            v-for="(item, index) in tab"
            class="sign-tab"
            :class="{ 'active-line': $route.path.includes(item.path) }"
            @click="jump(item.id, item.path)"
            v-clickout="hiddenChoice"
            @mouseenter="leverIschoice(item.id)"
            @mouseleave="leverNochoice(item.id)"
            :key="index"
          >
            <span class>{{ item.title }}</span>
            <svg-icon
              v-if="item.id === 12"
              class="text-22 ml-4"
              data_iconName="ic_cup_icon"
            ></svg-icon>
            <i
              class="el-icon-arrow-down"
              v-if="
                (item.id === 4 && !ischoice) ||
                  (item.id === 5 && !isfinancial) ||
                  (item.id === 6 && !isTrading) ||
                  (item.id === 11 && meta_switch && nft_entrance && !isNFT) ||
                  (item.id === 158 && !isforeign) ||
                  (item.id === 150 && !isStock) ||
                  (item.id === 160 && !isETF) ||
                  (item.id === 170 && !isCommodity)
              "
            ></i>
            <i
              class="el-icon-arrow-up"
              v-if="
                (item.id === 4 && ischoice) ||
                  (item.id === 5 && isfinancial) ||
                  (item.id === 6 && isTrading) ||
                  (item.id === 11 && meta_switch && nft_entrance && isNFT) ||
                  (item.id === 158 && isforeign) ||
                  (item.id === 150 && isStock) ||
                  (item.id === 160 && isETF) ||
                  (item.id === 170 && isCommodity)
              "
            ></i>
            <div class="lever-down" v-if="item.id === 4 && ischoice">
              <Choicelever v-on:choice="choice" :isLoginReg="isLoginReg" />
            </div>
            <div class="lever-down" v-if="item.id === 5 && isfinancial">
              <ChoiceFinancial
                :isLoginReg="isLoginReg"
                :wealth_management_status="wealth_management_status"
                :defi_ore_status="defi_ore_status"
                :currency_subscription_status="currency_subscription_status"
                :wealth_management_type="wealth_management_type"
                :currency_subscription_logo="currency_subscription_logo"
                :stock_subscription_status="stock_subscription_status"
                :pledge_borrowing_status="pledge_borrowing_status"
                :stock_subscription_logo="stock_subscription_logo"
                :defi_ore_logo="defi_ore_logo"
                :wealth_management_logo="wealth_management_logo"
              />
            </div>
            <div class="lever-down" v-if="item.id === 6 && isTrading">
              <ChoiceTrading
                :flash_against_status="flash_against_status"
                :currency_transaction_status="currency_transaction_status"
              />
            </div>
            <div class="lever-down" v-if="item.id === 158 && isforeign">
              <ChoiceForeign
                :foreign_exchange="foreign_exchange"
                :forex_second_lever="forex_second_lever"
              />
            </div>
            <div class="lever-down" v-if="item.id === 160 && isETF">
              <ChoiceETF
                :etf_spot="etf_spot"
                :etf_lever="etf_lever"
                :etf_second_lever="etf_second_lever"
              />
            </div>
            <div class="lever-down" v-if="item.id === 150 && isStock">
              <ChoiceStock
                :stock_status="stock_status"
                :stock_lever_status="stock_lever_status"
                :stock_second_lever_status="stock_second_lever_status"
              />
            </div>
            <div class="lever-down" v-if="item.id === 170 && isCommodity">
              <ChoiceCommodity
                :commodity_spot="commodity_spot"
                :commodity_lever="commodity_lever"
                :commodity_second_lever="commodity_second_lever"
              />
            </div>
            <div
              class="lever-down"
              v-if="item.id === 11 && meta_switch && nft_entrance && isNFT"
            >
              <ChoiceNft
                :meta_switch="meta_switch"
                :nft_entrance="nft_entrance"
                v-on:choice="choiceNft"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex share-color  align-end"
        :class="islogin ? 'logheader-right' : 'nologheader-right'"
      >
        <div class="log-box flex " v-if="!islogin">
          <router-link to="/login">
            <div
              class="login share-color text-14 point"
              :class="loginReg == 1 ? 'lin-btn' : ''"
              @click="changeLoginReg(1)"
            >
              {{ $t("login.login") }}
            </div>
          </router-link>
          <div class="w-40"></div>
          <router-link to="/register">
            <div
              class="regist  share-color text-14 point"
              :class="loginReg == 2 ? 'lin-btn' : ''"
              @click="changeLoginReg(2)"
            >
              {{ $t("register.register") }}
            </div>
          </router-link>
        </div>
        <div
          v-if="islogin && virtual_wallet"
          class="flex align-center share-color text-14 borderLight-bg radius-8 pd-demo-btn"
        >
          <div>{{ $t("header.demoAccount") }}</div>
          <div class="text-blue mg-lf-md point" @click="onChangeVirtualWallet">
            {{ $t("header.quitDemo") }}
          </div>
        </div>
        <div class="person-box js-st flex share-color point">
          <div class="other flex">
            <div
              class="round ml-30"
              @mouseenter="isperson = true"
              @mouseleave="isperson = false"
              v-if="islogin"
            >
              <i class="iconfont quote-key-text  icon-type-04-copy"></i>
              <!-- <img src="@/assets/images/head.png" alt /> -->
              <!-- 个人中心弹窗 -->
              <div class="person-buble" v-if="isperson">
                <Personbuble
                  :nickname="getuserinfo?.user_nickname"
                  :userid="getuserinfo?.id"
                  @onChangeVirtualWallet="onChangeVirtualWallet"
                />
              </div>
            </div>
            <div
              :class="islogin ? 'round ml-30' : 'round'"
              @click="jumppath('/announce')"
            >
              <svg-icon
                data-_icon-name="ic_head_gg"
                class=" text-24"
              ></svg-icon>
              <!-- <i class="iconfont quote-key-text mg-rt-md icon-gonggao-copy"></i> -->
            </div>
            <div
              class="round ml-30"
              v-show="androidInfo?.source_url || iosInfo?.source_url"
              @click="jumppath('/download')"
            >
              <svg-icon
                data-_icon-name="ic_head_download"
                class=" text-24"
              ></svg-icon>
              <!-- <i
                class="iconfont quote-key-text mg-rt-md icon-xian1-16-copy"
              ></i> -->
            </div>
            <div
              v-if="islogin"
              class="round ml-30"
              @mouseenter="isletter = true"
              @mouseleave="isletter = false"
            >
              <svg-icon
                data-_icon-name="ic_head_letter"
                class=" text-24"
              ></svg-icon>
              <!-- <img src="@/assets/images/head-letter.png" alt /> -->
              <!-- 未读标记 -->
              <div class="badge posi" v-if="unReadNum > 0"></div>
              <!-- 站内信弹窗 -->
              <div class="letter-buble" v-if="isletter">
                <Letterbuble @getnum="getUnread" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="lang-box text-14 "
          @click="islanguage = !islanguage"
          v-clickout="hide"
        >
          {{ curentLang[0]?.label }}
          <i class="el-icon-caret-bottom"></i>
          <Language
            ref="lang"
            @close="hide"
            :isselect="islanguage"
            :language="language"
            :isLoginReg="isLoginReg"
            :isFoot="false"
          />
        </div>
      </div>
    </div>
    <!-- 客服弹窗 -->
    <ServerBox
      @oncancer="dialog = false"
      @onconfirm="dialog = false"
      :dialog="dialog"
    />
    <!-- 法币交易三方 -->
    <ThirdFiatBox
      @oncancer="thirDialog = false"
      @onconfirm="thirDialog = false"
      :dialog="thirDialog"
    ></ThirdFiatBox>
  </div>
</template>

<script>
import Personbuble from "./person-buble.vue";
import Letterbuble from "./letter-buble.vue";
import Choicelever from "./choice-lever.vue";
import ChoiceFinancial from "./choice-financial.vue";
import ChoiceTrading from "./choice-trading.vue";
import ChoiceNft from "./choice-nft.vue";
import ChoiceForeign from "./choice-foreign.vue";
import ChoiceETF from "./choice-etf.vue";
import ChoiceStock from "./choice-stock.vue";
import ChoiceCommodity from "./choice-commodity.vue";
import Language from "@/components/homeModel/language.vue";
import { unreadStatistics } from "@/api/article";
import { getFastAgainstStatusApi } from "@/api/flash-against";
import { getConfig } from "@/api/user";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { watch, ref } from "vue";
import { ElMessage } from "element-plus";
import ServerBox from "@/components/homeModel/serverBox";
import jumpChat from "@/commonapi/chat";
import ThirdFiatBox from "@/components/homeModel/thirdFiatBox";
import { getAppRenewal } from "@/api/article";
import UseSystemSetting from "@/commonapi/useSystemSetting";
// import {getCurrentInstance} from "vue";

export default {
  name: "Header",
  components: {
    Personbuble,
    Letterbuble,
    Choicelever,
    ChoiceForeign,
    ChoiceETF,
    ChoiceCommodity,
    Language,
    ChoiceFinancial,
    ServerBox,
    ChoiceTrading,
    ChoiceNft,
    ThirdFiatBox,
    ChoiceStock,
  },
  props: { language: Array, isLoginReg: Boolean },
  setup() {
    const { chatActive } = jumpChat();
    // 获取开关状态
    const { virtual_wallet } = UseSystemSetting();
    const $route = useRoute();
    const path = ref("");
    watch(
      () => $route.path,
      (val) => {
        path.value = val;
        // console.log("pathName", val);
      }
    );
    return {
      chatActive,
      virtual_wallet,
      path,
    };
  },
  data() {
    return {
      tab: [
        // { title: this.$t("header.legal"),id:1 ,val: "legal"},
        // { title: this.$t("header.bibi"), path: "/deal", id: 3, val: "bibi" },
      ],
      theme: process.env.VUE_APP_NEW_LOGINREGPAGE,
      copyTab: [],
      islogin: this.$store.state.user.token ? true : false,
      isperson: false,
      ischoice: false, //选择秒合约弹窗
      isfinancial: false, //选择金融弹窗
      isforeign: false, //选择外汇弹窗
      isStock: false, //选择股票弹窗
      isETF: false, //选择ETF弹窗
      isCommodity: false, //选择大宗弹窗
      isletter: false,
      islanguage: false, //选择语言
      isTrading: false, //选择交易弹窗
      isNFT: false, //选择NFT和元宇宙弹窗
      curentLang: [], //当前语言
      unReadNum: 0, //站内信未读数量
      wealth_management_status: false,
      defi_ore_status: false,
      stock_status: false,
      currency_subscription_status: false,
      wealth_management_type: 1,
      currency_subscription_logo: "",
      wealth_management_logo: "",
      defi_ore_logo: "",
      fiat_currency_transaction_status: false, //法币
      systemInfos: null,
      easy_buy_type: 1,
      second_lever_status: false, //，秒合约
      lever_status: false, //合约
      flash_against_status: false, // 闪兑
      currency_transaction_status: false, //币币
      nft_entrance: false, //NFT
      meta_switch: false, //元宇宙页
      world_cup: false, //世界杯
      stock_lever_status: false,
      stock_second_lever_status: false,
      stock_subscription_status: false, //新股申购
      pledge_borrowing_status: false, //借贷
      foreign_exchange: false, //外汇
      forex_second_lever: false, //外汇期权
      etf_spot: false, //ETF现货
      etf_lever: false, //ETF现货
      etf_second_lever: false, //ETF期权
      commodity_spot: false, //大宗现货
      commodity_lever: false, //大宗合约
      commodity_second_lever: false, //大宗期权
      stock_subscription_logo: "",
      url: "",
      fiatStatus: true,
      dialog: false,
      service_url: "", //判断客服跳转类型
      thirDialog: false,
      androidInfo: {},
      iosInfo: {},
      loginReg: 1,
    };
  },

  computed: {
    getuserinfo() {
      return this.$store.state.user.userInfo;
    },
    getToken() {
      return this.$store.state.user.token;
    },
    local() {
      return this.$store.state.user.local;
    },
    levelPage() {
      return this.$store.state.user.levelPage;
    },
    nftPage() {
      console.log(this.$store.state.user.nftPage);
      return this.$store.state.user.nftPage;
    },
    languages() {
      return this.$store.state.user.localary;
    },
    accountRegTab() {
      return this.$store.state.user.accountRegTab;
    },
  },
  watch: {
    accountRegTab: function(val) {
      this.loginReg = val;
    },
    levelPage: function(val) {
      if (val) {
        console.log("levelPage");
        this.getTab();
      }
    },
    nftPage: function(val) {
      if (val) {
        this.getTab();
      }
    },
    virtual_wallet: function() {
      this.tab = [];
      this.getShowWealth();
    },
    getToken: function(data) {
      if (data) {
        console.log("token");
        this.islogin = true;
        this.getUnread();
      } else {
        this.islogin = false;
      }
    },
    local: function(val) {
      if (val) {
        this.curentLang = this.languages.filter((item) => {
          return item.language_tag === val;
        });
        this.getTab();
      }
    },
    "$route.query.local"(to, from) {
      console.log(to, from);
      if (to && to !== from) {
        // console.log(to);
        this.$store.dispatch("user/changeLocal", to).then(() => {
          this.getTab();
        });
        // let id = Number(to.query.id);
        // this.$store.dispatch('product/fetchProductDetail', { id });
      }
    },
    languages: function(val) {
      this.curentLang = val.filter((item) => {
        return item.language_tag === this.local;
      });
    },
    path: function(val) {
      if (val) {
        if (val.includes("/register")) {
          this.loginReg = 2;
        } else {
          this.loginReg = 1;
        }
      } else {
        this.loginReg = 1;
      }
    },
  },
  mounted() {
    this.$store = useStore();
    const p1 = this.getSysConfig();
    const p2 = this.getFastAgainstStatus();
    this.getAppRenewals(1);
    this.getAppRenewals(2);
    Promise.all([p1, p2]).then(() => {
      this.getShowWealth();
    });
    this.curentLang = this.languages.filter((item) => {
      return item.language_tag === this.local;
    });
  },
  methods: {
    //切换注册或者登录
    changeLoginReg(i) {
      this.loginReg = i;
      // this.$store.commit("user/SET_ACCOUNTREG_TAB", i);
    },
    //切换模拟账号状态
    onChangeVirtualWallet() {
      let that = this;
      that.$store.dispatch("user/changeVirtualWallet", !that.virtual_wallet);
    },
    getAppRenewals(type) {
      getAppRenewal(type).then((res) => {
        if (type === 1) {
          this.androidInfo = res.data;
        } else {
          this.iosInfo = res.data;
        }
      });
    },
    leverIschoice(id) {
      if (id === 4) {
        this.ischoice = true;
        this.isfinancial = false;
        this.isTrading = false;
        this.isNFT = false;
        this.isforeign = false;
        this.isETF = false;
        this.isCommodity = false;
        this.isStock = false;
      }
      if (id === 5) {
        this.ischoice = false;
        this.isfinancial = true;
        this.isTrading = false;
        this.isNFT = false;
        this.isforeign = false;
        this.isETF = false;
        this.isCommodity = false;
        this.isStock = false;
      }
      if (id === 6) {
        this.ischoice = false;
        this.isfinancial = false;
        this.isTrading = true;
        this.isNFT = false;
        this.isforeign = false;
        this.isETF = false;
        this.isCommodity = false;
        this.isStock = false;
      }
      if (id === 11 && this.meta_switch && this.nft_entrance) {
        this.ischoice = false;
        this.isfinancial = false;
        this.isTrading = false;
        this.isNFT = true;
        this.isforeign = false;
        this.isETF = false;
        this.isCommodity = false;
        this.isStock = false;
      }
      if (id === 158) {
        this.ischoice = false;
        this.isfinancial = false;
        this.isTrading = false;
        this.isNFT = false;
        this.isforeign = true;
        this.isETF = false;
        this.isCommodity = false;
        this.isStock = false;
      }
      if (id === 150) {
        this.ischoice = false;
        this.isfinancial = false;
        this.isTrading = false;
        this.isNFT = false;
        this.isforeign = false;
        this.isETF = false;
        this.isCommodity = false;
        this.isStock = true;
      }
      if (id === 160) {
        this.ischoice = false;
        this.isfinancial = false;
        this.isTrading = false;
        this.isNFT = false;
        this.isforeign = false;
        this.isETF = true;
        this.isCommodity = false;
        this.isStock = false;
      }
      if (id === 170) {
        this.ischoice = false;
        this.isfinancial = false;
        this.isTrading = false;
        this.isNFT = false;
        this.isforeign = false;
        this.isETF = false;
        this.isCommodity = true;
        this.isStock = false;
      }
    },
    leverNochoice(id) {
      if (id === 4) {
        this.ischoice = false;
      }
      if (id === 5) {
        this.isfinancial = false;
      }
      if (id === 6) {
        this.isTrading = false;
      }
      if (id === 11 && this.meta_switch && this.nft_entrance) {
        this.isNFT = false;
      }
      if (id == 158) {
        this.isforeign = false;
      }
      if (id == 150) {
        this.isStock = false;
      }
      if (id == 160) {
        this.isETF = false;
      }
      if (id == 170) {
        this.isCommodity = false;
      }
    },
    getTab() {
      let list = [...this.tab];
      list.forEach((ele) => {
        if (ele.val) {
          if (ele.id === 4) {
            ele.val = this.levelPage === "level" ? "lever" : this.levelPage;
            ele.title =
              this.levelPage === "level"
                ? this.$t("header.lever")
                : this.$t("header." + this.levelPage);
          } else if (ele.id === 11) {
            ele.val = this.nftPage === "NFT" ? "NFT" : this.nftPage;
            ele.title =
              this.nftPage === "NFT" ? "NFT" : this.$t("universe.universe");
          } else if (ele.id === 151) {
            ele.title = this.$t("stock.stockInvestment");
          } else if (ele.id === 152) {
            ele.title = this.$t("stock.stockLever");
          } else if (ele.id === 153) {
            ele.title = this.$t("stock.stockSecond");
          } else if (ele.id === 12) {
            ele.title = this.$t("worldCup.worldCup");
          } else if (ele.id === 13) {
            ele.title = this.$t("pledge.pledgeLoan");
          } else if (ele.id === 14) {
            ele.title = this.$t("newCoin.subscription");
          } else if (ele.id === 154) {
            ele.title = this.$t("newCoin.stockSubscription");
          } else if (ele.id === 155) {
            ele.title = this.$t("loan.loan");
          } else if (ele.id === 156) {
            ele.title = this.$t("foreign.forexTrad");
          } else if (ele.id === 158) {
            ele.title = this.$t("foreign.forex");
          } else if (
            ele.id === 150 ||
            ele.id === 151 ||
            ele.id === 152 ||
            ele.id === 153
          ) {
            ele.title = this.$t("stock." + ele.val);
          } else if (ele.id === 160) {
            ele.title = "ETF";
          } else if (
            ele.id === 161 ||
            ele.id === 162 ||
            ele.id === 163 ||
            ele.id === 170 ||
            ele.id === 171 ||
            ele.id === 172 ||
            ele.id === 173
          ) {
            ele.title = this.$t("ETFCommodity." + ele.val);
          } else if (ele.id === 15) {
            ele.title = this.$t("hosting.robotHosting");
          } else if (ele.id === 1) {
            ele.title = this.$t("newTheme.BuyCoins");
          } else {
            ele.title = this.$t("header." + ele.val);
          }
        }
      });
      this.tab = [...list];
      this.copyTab = [...list];
    },
    getSysConfig() {
      return new Promise((resolve) => {
        let that = this;
        getConfig().then((res) => {
          if (res.code === 200) {
            that.systemInfos = res.data;
            resolve();
          }
        });
      });
    },
    getFastAgainstStatus() {
      return new Promise((resolve) => {
        getFastAgainstStatusApi().then((res) => {
          this.flash_against_status = res.data;
          this.$store.commit("user/SET_FASTEXCHANGE", res.data);
          resolve();
        });
      });
    },
    //判断是否要显示
    getShowWealth() {
      this.systemInfos?.forEach((ele) => {
        if (ele.key === "wealth_management_status") {
          this.wealth_management_status = ele.value;
        }
        if (ele.key === "defi_ore_status") {
          this.defi_ore_status = ele.value;
        }
        if (ele.key === "stock_status") {
          this.stock_status = ele.value;
        }
        if (ele.key === "currency_subscription_status") {
          this.currency_subscription_status = ele.value;
        }
        if (ele.key === "wealth_management_type") {
          this.wealth_management_type = ele.value;
        }
        if (ele.key === "stock_subscription_logo") {
          this.stock_subscription_logo = ele.value;
        }
        if (ele.key === "stock_subscription_status") {
          this.stock_subscription_status = ele.value;
        }
        if (ele.key === "pledge_borrowing_status") {
          this.pledge_borrowing_status = ele.value;
        }
        if (ele.key === "currency_subscription_logo") {
          this.currency_subscription_logo = ele.value;
        }
        if (ele.key === "wealth_management_logo") {
          this.wealth_management_logo = ele.value;
        }
        if (ele.key === "defi_ore_logo") {
          this.defi_ore_logo = ele.value;
        }
        if (ele.key === "fiat_currency_transaction_status") {
          this.fiat_currency_transaction_status = ele.value;
        }
        if (ele.key == "easy_buy_type") {
          this.easy_buy_type = ele.value;
        }
        if (ele.key == "foreign_exchange") {
          this.foreign_exchange = ele.value;
        }
        if (ele.key == "forex_second_lever") {
          this.forex_second_lever = ele.value;
        }
        if (ele.key == "etf_spot") {
          this.etf_spot = ele.value;
        }
        if (ele.key == "etf_lever") {
          this.etf_lever = ele.value;
        }
        if (ele.key == "etf_second_lever") {
          this.etf_second_lever = ele.value;
        }
        if (ele.key == "commodity_spot") {
          this.commodity_spot = ele.value;
        }
        if (ele.key == "commodity_lever") {
          this.commodity_lever = ele.value;
        }
        if (ele.key == "commodity_second_lever") {
          this.commodity_second_lever = ele.value;
        }
        if (ele.key == "easy_buy_url") {
          this.url = ele.value;
        }
        if (ele.key == "second_lever_status") {
          this.second_lever_status = ele.value;
        }
        if (ele.key == "lever_status") {
          this.lever_status = ele.value;
        }
        if (ele.key == "currency_transaction_status") {
          this.currency_transaction_status = ele.value;
        }
        if (ele.key == "nft_entrance") {
          this.nft_entrance = ele.value;
        }
        if (ele.key == "meta_switch") {
          this.meta_switch = ele.value;
        }
        if (ele.key == "world_cup") {
          this.world_cup = ele.value;
        }
        if (ele.key == "stock_lever_status") {
          this.stock_lever_status = ele.value;
        }
        if (ele.key == "stock_second_lever_status") {
          this.stock_second_lever_status = ele.value;
        }
        // if (ele.key == "virtual_wallet") {
        //   this.virtual_wallet = ele.value;
        // }
        if (ele.key === "service_url") {
          const show = ele.value; //online-在线客服页；contact-社交客服页
          this.service_url = show;
          this.dialog = false;
          if (this.service_url === "contact") {
            // 在线客服打开注释
            // zE(function() {
            //   zE.hide();
            // });
          }
        }
      });
      let list = [...this.tab];
      list.forEach((ele) => {
        if (ele.id === 14) {
          ele.title = this.$t("newCoin.subscription");
        } else if (ele.id === 154) {
          ele.title = this.$t("newCoin.stockSubscription");
        } else if (ele.id === 155) {
          ele.title = this.$t("loan.loan");
        } else if (ele.id === 156) {
          ele.title = this.$t("foreign.forexTrad");
        } else if (ele.id === 158) {
          ele.title = this.$t("foreign.forex");
        } else if (
          ele.id === 150 ||
          ele.id === 151 ||
          ele.id === 152 ||
          ele.id === 153
        ) {
          ele.title = this.$t("stock." + ele.val);
        } else if (ele.id === 160) {
          ele.title = "ETF";
        } else if (
          ele.id === 161 ||
          ele.id === 162 ||
          ele.id === 163 ||
          ele.id === 170 ||
          ele.id === 171 ||
          ele.id === 172 ||
          ele.id === 173
        ) {
          ele.title = this.$t("ETFCommodity." + ele.val);
        } else if (ele.id === 1) {
          ele.title = this.$t("newTheme.BuyCoins");
        } else {
          ele.title = this.$t("header." + ele.val);
        }
      });
      /**
       * 1legal,2quote，5financial,6trading，7flashAgainst，8bibi,9wealthTreasure,15robotHosting,16和4lever，17second
       * 10DefiMining,11NFT和universe,12cup,14subscription
       */
      if (
        (this.currency_transaction_status ||
          this.lever_status ||
          this.second_lever_status ||
          this.stock_status ||
          this.second_lever_status ||
          this.stock_second_lever_status) &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("header.quote"),
          path: "/quote",
          id: 2,
          val: "quote",
        });
      }
      if (
        this.flash_against_status &&
        this.currency_transaction_status &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("header.trading"),
          path: "trading",
          id: 6,
          val: "trading",
        });
      } else if (
        this.flash_against_status &&
        !this.currency_transaction_status &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("header.flashAgainst"),
          path: "/flashAgainst",
          id: 7,
          val: "flashAgainst",
        });
      } else if (
        this.currency_transaction_status &&
        ((!this.flash_against_status && !this.virtual_wallet) ||
          this.virtual_wallet)
      ) {
        list.push({
          title: this.$t("header.bibi"),
          path: "/deal",
          id: 8,
          val: "bibi",
        });
      }
      if (this.second_lever_status || this.lever_status) {
        if (!this.lever_status && this.second_lever_status) {
          list.push({
            title: this.$t("header.second"),
            path: "secondlever",
            id: 17,
            val: "second",
          });
        } else if (this.lever_status && !this.second_lever_status) {
          list.push({
            title: this.$t("header.lever"),
            path: "lever",
            id: 16,
            val: "lever",
          });
        } else {
          list.push({
            title: this.$t("header.lever"),
            path: "lever",
            id: 4,
            val: "lever",
          });
        }
      }
      if (this.fiat_currency_transaction_status && !this.virtual_wallet) {
        let obj = {
          title: this.$t("newTheme.BuyCoins"),
          path: "/fiat",
          id: 1,
          val: "legal",
        };
        list.splice(1, 0, obj);
      }
      if (
        (this.wealth_management_status ||
          this.defi_ore_status ||
          this.currency_subscription_status ||
          this.stock_subscription_status ||
          this.pledge_borrowing_status) &&
        !this.virtual_wallet
      ) {
        if (
          (this.wealth_management_status && this.defi_ore_status) ||
          (this.defi_ore_status && this.currency_subscription_status) ||
          (this.wealth_management_status &&
            this.currency_subscription_status) ||
          (this.stock_subscription_status && this.wealth_management_status) ||
          (this.stock_subscription_status && this.defi_ore_status) ||
          (this.currency_subscription_status &&
            this.stock_subscription_status) ||
          (this.pledge_borrowing_status && this.wealth_management_status) ||
          (this.pledge_borrowing_status && this.defi_ore_status) ||
          (this.pledge_borrowing_status && this.currency_subscription_status) ||
          (this.pledge_borrowing_status && this.stock_subscription_status)
        ) {
          list.push({
            title: this.$t("header.financial"),
            path: "Financial",
            id: 5,
            val: "financial",
          });
        } else if (
          this.wealth_management_status &&
          !this.defi_ore_status &&
          !this.currency_subscription_status &&
          !this.stock_subscription_status &&
          !this.pledge_borrowing_status
        ) {
          if (this.wealth_management_type === 1) {
            list.push({
              title: this.$t("header.wealthTreasure"),
              path: "/wealthFinancial",
              id: 9,
              val: "wealthTreasure",
            });
          } else {
            list.push({
              title: this.$t("hosting.robotHosting"),
              path: "/wealthFinancial",
              id: 15,
              val: "robotHosting",
            });
          }
        } else if (
          !this.wealth_management_status &&
          this.defi_ore_status &&
          !this.currency_subscription_status &&
          !this.stock_subscription_status &&
          !this.pledge_borrowing_status
        ) {
          list.push({
            title: this.$t("header.DefiMining"),
            path: "/defiFinancial",
            id: 10,
            val: "DefiMining",
          });
        } else if (
          !this.wealth_management_status &&
          !this.defi_ore_status &&
          this.currency_subscription_status &&
          !this.stock_subscription_status &&
          !this.pledge_borrowing_status
        ) {
          list.push({
            title: this.$t("newCoin.subscription"),
            path: "/sgdSubscription",
            id: 14,
            val: "subscription",
          });
        } else if (
          !this.wealth_management_status &&
          !this.defi_ore_status &&
          !this.currency_subscription_status &&
          this.stock_subscription_status &&
          !this.pledge_borrowing_status
        ) {
          list.push({
            title: this.$t("newCoin.stockSubscription"),
            path: "/newIPO",
            id: 154,
            val: "stockSubscription",
          });
        } else if (
          !this.wealth_management_status &&
          !this.defi_ore_status &&
          !this.currency_subscription_status &&
          !this.stock_subscription_status &&
          this.pledge_borrowing_status
        ) {
          list.push({
            title: this.$t("loan.loan"),
            path: "/loan",
            id: 155,
            val: "loan",
          });
        }
      }
      if (this.nft_entrance && !this.meta_switch && !this.virtual_wallet) {
        this.$store.commit("user/SET_NFT_PAGE", "NFT");
        list.push({
          title: "NFT",
          path: "/nftHome",
          id: 11,
          val: "NFT",
        });
      } else if (this.meta_switch && !this.virtual_wallet) {
        this.$store.commit("user/SET_NFT_PAGE", "universe");
        list.push({
          title: this.$t("universe.universe"),
          path: "/universeHome",
          id: 11,
          val: "universe",
        });
      }
      if (this.world_cup && !this.virtual_wallet) {
        list.push({
          title: this.$t("worldCup.worldCup"),
          path: "/cup",
          id: 12,
          val: "worldCup",
        });
      }
      //股票相关页面

      if (
        (this.stock_status && this.stock_lever_status) ||
        (this.stock_second_lever_status && this.stock_lever_status) ||
        (this.stock_second_lever_status && this.stock_status)
      ) {
        list.push({
          title: this.$t("stock.stock"),
          path: "/Stock",
          id: 150,
          val: "stock",
        });
      } else if (
        this.stock_status &&
        !this.stock_lever_status &&
        !this.stock_second_lever_status
      ) {
        list.push({
          title: this.$t("stock.stockInvestment"),
          path: "/Stock",
          id: 151,
          val: "stockInvestment",
        });
      } else if (
        this.stock_lever_status &&
        !this.stock_status &&
        !this.stock_second_lever_status
      ) {
        list.push({
          title: this.$t("stock.stockLever"),
          path: "/stockLever",
          id: 152,
          val: "stockLever",
        });
      } else if (
        !this.stock_status &&
        !this.stock_lever_status &&
        this.stock_second_lever_status
      ) {
        list.push({
          title: this.$t("stock.stockSecond"),
          path: "/stockSecond",
          id: 153,
          val: "stockSecond",
        });
      }
      // if (this.stock_status) {
      //   list.push({
      //     title: this.$t("stock.stockInvestment"),
      //     path: "/Stock",
      //     id: 151,
      //     val: "stockInvestment",
      //   });
      // }
      // if (this.stock_lever_status) {
      //   list.push({
      //     title: this.$t("stock.stockLever"),
      //     path: "/stockLever",
      //     id: 152,
      //     val: "stockLever",
      //   });
      // }
      // if (this.stock_second_lever_status) {
      //   list.push({
      //     title: this.$t("stock.stockSecond"),
      //     path: "/stockSecond",
      //     id: 153,
      //     val: "stockSecond",
      //   });
      // }
      //判断外汇下拉
      if (
        this.foreign_exchange &&
        this.forex_second_lever &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("foreign.forex"),
          path: "foreign",
          id: 158,
          val: "forex",
        });
      } else if (
        this.foreign_exchange &&
        !this.forex_second_lever &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("foreign.forexTrad"),
          path: "/foreignExchange",
          id: 156,
          val: "forexTrad",
        });
      } else if (
        this.forex_second_lever &&
        ((!this.foreign_exchange && !this.virtual_wallet) ||
          this.virtual_wallet)
      ) {
        list.push({
          title: this.$t("ETFCommodity.foreignSecond"),
          path: "/foreignSecond",
          id: 157,
          val: "foreignSecond",
        });
      }
      //判断ETF下拉
      if (
        ((this.etf_spot && this.etf_lever) ||
          (this.etf_second_lever && this.etf_spot) ||
          (this.etf_second_lever && this.etf_lever)) &&
        !this.virtual_wallet
      ) {
        list.push({
          title: "ETF",
          path: "etf",
          id: 160,
          val: "ETF",
        });
      } else if (
        this.etf_spot &&
        !this.etf_lever &&
        !this.etf_second_lever &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("ETFCommodity.etfSpot"),
          path: "/etf",
          id: 161,
          val: "etfSpot",
        });
      } else if (
        this.etf_lever &&
        ((!this.etf_spot && !this.etf_second_lever && !this.virtual_wallet) ||
          this.virtual_wallet)
      ) {
        list.push({
          title: this.$t("ETFCommodity.etfLever"),
          path: "/etfLever",
          id: 162,
          val: "etfLever",
        });
      } else if (
        !this.etf_spot &&
        !this.etf_lever &&
        this.etf_second_lever &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("ETFCommodity.etfSecond"),
          path: "/etfSecond",
          id: 163,
          val: "etfSecond",
        });
      }
      //判断大宗下拉
      if (
        ((this.commodity_spot && this.commodity_lever) ||
          (this.commodity_second_lever && this.commodity_spot) ||
          (this.commodity_second_lever && this.commodity_lever)) &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("ETFCommodity.commodities"),
          path: "commodity",
          id: 170,
          val: "commodities",
        });
      } else if (
        this.commodity_spot &&
        !this.commodity_lever &&
        !this.commodity_second_lever &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("ETFCommodity.commoditySpot"),
          path: "/commodity",
          id: 171,
          val: "commoditySpot",
        });
      } else if (
        this.commodity_lever &&
        ((!this.commodity_spot &&
          !this.commodity_second_lever &&
          !this.virtual_wallet) ||
          this.virtual_wallet)
      ) {
        list.push({
          title: this.$t("ETFCommodity.commodityLever"),
          path: "/commodityLever",
          id: 172,
          val: "commodityLever",
        });
      } else if (
        !this.commodity_spot &&
        !this.commodity_lever &&
        this.commodity_second_lever &&
        !this.virtual_wallet
      ) {
        list.push({
          title: this.$t("ETFCommodity.commoditySecond"),
          path: "/commoditySecond",
          id: 173,
          val: "commoditySecond",
        });
      }
      this.tab = [...list];
      console.log(this.tab);
      this.getTab();
    },
    jumppath(path) {
      this.$router.push(path);
    },
    backhome() {
      this.$router.push("/");
    },
    hiddenChoice() {
      this.ischoice = false;
      this.isfinancial = false;
      this.isTrading = false;
      this.NFT = false;
    },
    choice(name) {
      if (this.fiat_currency_transaction_status) {
        let indexTab = this.tab.findIndex((ele) => {
          return ele.id === 4;
        });
        this.tab[indexTab].title = name;
      } else {
        let indexTab = this.tab.findIndex((ele) => {
          return ele.id === 6;
        });
        this.tab[indexTab].title = name;
      }
    },
    choiceNft(name) {
      if (this.tab && this.tab.length > 0) {
        this.tab.forEach((ele) => {
          if (ele.id === 11) {
            ele.title = name;
          }
        });
      }
    },
    jump(i, path) {
      // if (id == 160) {
      //   this.isETF = false;
      // }
      // if (id == 170) {
      //   this.isCommodity = false;
      // }
      if (i === 4) {
        this.ischoice = !this.ischoice;
        this.isfinancial = false;
        this.isTrading = false;
        this.isNFT = false;
        this.isforeign = false;
        this.isCommodity = false;
        this.isETF = false;
        this.isStock = false;
      } else if (i === 5) {
        this.isfinancial = !this.isfinancial;
        this.ischoice = false;
        this.isTrading = false;
        this.isNFT = false;
        this.isforeign = false;
        this.isCommodity = false;
        this.isETF = false;
        this.isStock = false;
      } else if (i === 6) {
        this.isTrading = !this.isTrading;
        this.ischoice = false;
        this.isfinancial = false;
        this.isNFT = false;
        this.isforeign = false;
        this.isCommodity = false;
        this.isETF = false;
        this.isStock = false;
      } else if (i === 158) {
        this.isforeign = !this.isforeign;
        this.isTrading = false;
        this.ischoice = false;
        this.isfinancial = false;
        this.isNFT = false;
        this.isCommodity = false;
        this.isETF = false;
        this.isStock = false;
      } else if (i === 150) {
        this.isforeign = false;
        this.isTrading = false;
        this.ischoice = false;
        this.isfinancial = false;
        this.isNFT = false;
        this.isETF = false;
        this.isCommodity = false;
        this.isStock = !this.isStock;
      } else if (i === 160) {
        this.isforeign = false;
        this.isTrading = false;
        this.ischoice = false;
        this.isfinancial = false;
        this.isNFT = false;
        this.isETF = !this.isETF;
        this.isCommodity = false;
        this.isStock = false;
      } else if (i === 170) {
        this.isforeign = false;
        this.isTrading = false;
        this.ischoice = false;
        this.isfinancial = false;
        this.isNFT = false;
        this.isCommodity = !this.isCommodity;
        this.isETF = false;
        this.isStock = false;
      } else if (i === 11 && this.meta_switch && this.nft_entrance) {
        this.isTrading = false;
        this.ischoice = false;
        this.isfinancial = false;
        this.isNFT = !this.isNFT;
        this.isforeign = false;
        this.isCommodity = false;
        this.isETF = false;
        this.isStock = false;
      } else if (i === 1) {
        if (this.easy_buy_type === 2) {
          if (this.service_url === "contact") {
            this.dialog = true;
          } else {
            this.chatActive();
            // 客服代码
            // zE.activate();
          }
        } else if (this.easy_buy_type === 1) {
          const url = this.url;
          const a = document.createElement("a");
          a.target = "_blank";
          a.href = url;
          a.click();
        } else if (this.easy_buy_type === 3) {
          if (!this.fiat_currency_transaction_status) {
            ElMessage.error(this.$t("formTip.noOpen"));
            return false;
          }
          this.$router.push("/fiat");
        } else if (this.easy_buy_type === 4) {
          this.thirDialog = true;
        } else if (this.easy_buy_type === 5) {
          this.$router.push("/fiatThird");
        }
      } else {
        this.$router.push(path);
      }
      console.log("跳转", path);
      console.log(this.ischoice, this.isfinancial, this.isTrading);
    },
    // 获取站内信未读数量
    getUnread() {
      unreadStatistics().then((res) => {
        console.log(res, "getUnread");
        if (res.code === 200) {
          this.unReadNum = res.data.value;
        }
      });
    },
    hide() {
      this.islanguage = false;
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  position: fixed;
  z-index: 2005;
  top: 0;
  left: 0;
}
.transparent {
  background-image: url("../../assets/theme1/loginbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
}
.header-bg {
  background-color: #4db4a7;
}
.header-left {
  display: flex;
  justify-content: space-between;
  img {
    width: 98px;
    height: 42px;
    margin-inline-end: 62px;
  }
}
.titletab {
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
.nologheader-right {
  // width: 313px;
  justify-content: space-between;
}
.logheader-right {
  // width: 323px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.log-box {
  align-items: center;
}
.w-40 {
  width: 40px;
}
.regist {
  // width: 48px;
  // height: 24px;
  padding: 2px 10px;
  border-radius: 4px;
  line-height: 24px;
}
.login {
  padding: 2px 10px;
  line-height: 24px;
}
.lang-box {
  height: 24px;
  min-width: 96px;
  line-height: 24px;
  border: 1px solid white;
  border-radius: 6px;
  position: relative;
  z-index: 100;
}
.person-box {
  max-width: 256px;
  margin: 0 55px;
  height: 100%;
  align-items: center;
}
.other {
  // width: 183px;
  height: 100%;
}
.ml-30 {
  margin-inline-start: 30px;
}
.round {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  i {
    font-size: 22px;
  }
}
// 选择合约交易弹窗
.sign-tab {
  position: relative;
  height: 100%;
  // line-height: 60px;
  padding-top: 20px;
  padding-bottom: 15px;
  margin-inline-end: 30px;
}
.lever-down {
  width: 140px;
  position: absolute;
  right: -53px;
  top: 45px;
}
// 个人中心弹窗
.person-buble {
  max-width: 240px;
  min-width: 170px;
  position: absolute;
  top: 60px;
  left: -85px;
  // padding: 10px 0;
}
// 站内信弹窗
.letter-buble {
  width: 260px;
  position: absolute;
  top: 60px;
  left: -130px;
  padding: 10px 0;
}
.badge {
  background: @yellow;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.posi {
  position: absolute;
  right: 0;
  top: 15px;
}
.text-24 {
  font-size: 24px;
}
.ml-4 {
  margin-inline-start: 4px;
}
.pd-demo-btn {
  padding: 5px 10px;
  margin-right: -55px;
}
.thirdbox {
  :deep(.el-dialog__body) {
    padding: 10px 30px 40px 30px;
  }
  :deep(.el-dialog__header) {
    border-bottom: none;
  }
}
.transparent-theme {
  background: linear-gradient(90deg, #040404 0%, #474747 100%);
}
</style>
