import request from "../utils/request";

// 大宗交易对
/**
 * 自选/取消自选
 */
export function commodityCollect(params) {
  return request({
    url: "/v1/commodity/collect",
    method: "GET",
    params,
  });
}

/**
 * 置顶/取消置顶
 */
export function commodityCollectTop(params) {
  return request({
    url: "/v1/commodity/collect/top",
    method: "GET",
    params,
  });
}

/**
 * 列表
 */
export function commodityList(params) {
  return request({
    url: "/v1/commodity/list",
    method: "GET",
    params,
  });
}

/**
 * 详情
 */
export function commodityDetail(type, id) {
  return request({
    url: `/v1/commodity/${type}/${id}`,
    method: "GET",
  });
}
/**
 * 交易时间
 */
export function commodityTime() {
  return request({
    url: "/v1/commodity/time",
    method: "GET",
  });
}

//大宗合约

/**
 *合约交易撤单
 * @returns
 */
export function OrderCommodityLeverCancelOrder(id) {
  return request({
    url: `/v1/OrderCommodityLever/cancelOrder/${id}`,
    method: "GET",
  });
}
/**
 *合约交易订单平仓
 * @returns
 */
export function OrderCommodityLeverClose(id) {
  return request({
    url: `/v1/OrderCommodityLever/close/${id}`,
    method: "GET",
  });
}
/**
 *合约交易订单详情
 * @returns
 */
export function OrderCommodityLeverDetails(id) {
  return request({
    url: `/v1/OrderCommodityLever/details/${id}`,
    method: "GET",
  });
}
/**
 *合约费率列表
 * @returns
 */
export function OrderCommodityLeverFeeList() {
  return request({
    url: `/v1/OrderCommodityLever/leverFeeList`,
    method: "GET",
  });
}
/**
 *合约下单信息
 * @returns
 */
export function OrderCommodityLeverInfo(commodity_id) {
  return request({
    url: `/v1/OrderCommodityLever/leverInfo/${commodity_id}`,
    method: "GET",
  });
}
/**
 * 大宗合约交易下单
 * @param {*} data
 * @returns
 */
export function OrderCommodityLeverMakeOrder(data) {
  return request({
    url: "/v1/OrderCommodityLever/leverOrder",
    method: "POST",
    data,
  });
}
/**
 * 大宗合约交易记录
 * @param {*} params
 * @returns
 */
export function OrderCommodityLeverOrderPage(params) {
  return request({
    url: "/v1/OrderCommodityLever/leverPage",
    method: "GET",
    params: params,
  });
}
/**
 * 大宗合约当前委托
 * @param {*} params
 * @returns
 */
export function OrderCommodityLeverPendingPage(params) {
  return request({
    url: "/v1/OrderCommodityLever/pendingPage",
    method: "GET",
    params: params,
  });
}
/**
 * 大宗合约合约持仓
 * @param {*} params
 * @returns
 */
export function OrderCommodityLeverPositionPage(params) {
  return request({
    url: "/v1/OrderCommodityLever/positionPage",
    method: "GET",
    params: params,
  });
}
/**
 *开通大宗合约账户
 * @param {
 * } params
 * @returns
 */
export function OrderCommodityLeverOpenLever() {
  return request({
    url: "/v1/OrderCommodityLever/openLever",
    method: "GET",
  });
}
/**
 *设置止盈止损
 * @param {
 * } data
 * @returns
 */
export function OrderCommodityLeverSetLeverProfit(data) {
  return request({
    url: "/v1/OrderCommodityLever/setLeverProfit",
    method: "POST",
    data,
  });
}

//大宗期权
/**
 *大宗期权取消订单
 * @returns
 */
export function OrderCommoditySecondLeverCancelOrder(id) {
  return request({
    url: `/v1/OrderCommoditySecondLever/cancelOrder/${id}`,
    method: "GET",
  });
}
/**
 *大宗期权详情
 * @returns
 */
export function OrderCommoditySecondLeverDetails(id) {
  return request({
    url: `/v1/OrderCommoditySecondLever/details/${id}`,
    method: "GET",
  });
}
/**
 *大宗期权费率列表
 * @returns
 */
export function OrderCommoditySecondLeverFeeList() {
  return request({
    url: `/v1/OrderCommoditySecondLever/handlingFeeList`,
    method: "GET",
  });
}
/**
 *大宗期权下单信息
 * @returns
 */
export function OrderCommoditySecondLeverInfo(commodity_id) {
  return request({
    url: `/v1/OrderCommoditySecondLever/orderSecondLeverInfo/${commodity_id}`,
    method: "GET",
  });
}
/**
 * 大宗期权交易下单
 * @param {*} data
 * @returns
 */
export function OrderCommoditySecondLeverMakeOrder(data) {
  return request({
    url: "/v1/OrderCommoditySecondLever/orderSecondLever",
    method: "POST",
    data,
  });
}
/**
 * 大宗期权当前委托
 * @param {*} params
 * @returns
 */
export function OrderCommoditySecondLeverPendingPage(params) {
  return request({
    url: "/v1/OrderCommoditySecondLever/pendingPage",
    method: "GET",
    params: params,
  });
}

/**
 * 大宗期权当前持仓
 * @param {*} params
 * @returns
 */
export function OrderCommoditySecondLeverPositionPage(params) {
  return request({
    url: "/v1/OrderCommoditySecondLever/positionPage",
    method: "GET",
    params: params,
  });
}
/**
 * 大宗期权交易记录
 * @param {*} params
 * @returns
 */
export function OrderCommoditySecondLeverOrderPage(params) {
  return request({
    url: "/v1/OrderCommoditySecondLever/recordPage",
    method: "GET",
    params: params,
  });
}

// 大宗现货

//大宗账户信息
export function orderCommodityAccount(params) {
  return request({
    url: "/v1/orderCommodity/account",
    method: "GET",
    params: params,
  });
}
//大宗现货交易撤单
export function orderCommodityCancelOrder(id) {
  return request({
    url: `/v1/orderCommodity/cancelOrder/${id}`,
    method: "GET",
  });
}
//大宗现货下单
export function orderCommodityMakeOrder(data) {
  return request({
    url: "/v1/orderCommodity/makeOrder",
    method: "POST",
    data,
  });
}

//大宗现货当前委托列表
export function orderCommodityPendingPage(params) {
  return request({
    url: "/v1/orderCommodity/pendingPage",
    method: "GET",
    params: params,
  });
}

//大宗现货当前持仓列表
export function orderCommodityPositionPage(params) {
  return request({
    url: "/v1/orderCommodity/positionPage",
    method: "GET",
    params: params,
  });
}

//大宗现货持仓记录
export function orderCommodityPositionRecordPage(params) {
  return request({
    url: "/v1/orderCommodity/positionRecordPage",
    method: "GET",
    params: params,
  });
}

//大宗现货交易记录
export function orderCommodityRecordPage(params) {
  return request({
    url: "/v1/orderCommodity/recordPage",
    method: "GET",
    params: params,
  });
}

/**
 *大宗现货费率列表
 * @returns
 */
export function commoditySpotFeeList() {
  return request({
    url: `/v1/orderCommodity/spotFeeList`,
    method: "GET",
  });
}

//大宗K线行情分页列表
export function commodityQuotationKlinePage(params) {
  return request({
    url: "/v1/commodityQuotation/kline/page",
    method: "GET",
    params: params,
  });
}

//K线行情列表(时间段)
export function commodityQuotationSeoPage(params) {
  return request({
    url: "/v1/commodityQuotation/seo/list",
    method: "GET",
    params: params,
  });
}

/**
 *commodity现货盘口数据
 * @param {
 * } params
 * @returns
 */
export function commodityQuotationSpot(id) {
  return request({
    url: `/v1/commodityQuotation/spot/${id}`,
    method: "GET",
  });
}

/**
 *commodity合约盘口数据
 * @param {
 * } params
 * @returns
 */
export function commodityQuotationLever(id) {
  return request({
    url: `/v1/commodityQuotation/lever/${id}`,
    method: "GET",
  });
}
