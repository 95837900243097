import request from "../utils/request";

/**
 * 获取闪兑状态
 */
export function getFastAgainstStatusApi(params) {
  return request({
    url: "/v1/currency/fastExchange/status",
    method: "GET",
    params,
  });
}

/**
 * 币种列表
 */
export function getCurrencyListApi(params) {
  return request({
    url: "/v1/currency/fastExchange/currencyList",
    method: "GET",
    params,
  });
}

/**
 * 获取汇率
 */
export function getExchangeRateApi(inId, outId) {
  return request({
    url: `/v1/currency/fastExchange/${inId}/${outId}/rate`,
    method: "GET",
  });
}

/**
 * 兑换
 */
export function submitExchangeApi(data) {
  return request({
    url: `/v1/currency/fastExchange/conversion`,
    method: "POST",
    data,
  });
}

/**
 * 闪兑记录
 */
export function getRecordListApi(params) {
  return request({
    url: `/v1/currency/fastExchange/page`,
    method: "GET",
    params,
  });
}
