import store from "../store";
import { ElMessageBox } from "element-plus";
import VueI18n from "../language";
import router from "../router";
import { rideFix, reduceFix, exceptFix } from "@/utils/precisionMath";
import { getUsTime } from "@/utils/times.js";
export function Copy(str) {
  return new Promise(function(resolve) {
    let text = str;
    let inputElement = document.createElement("input");
    inputElement.value = text;
    document.body.appendChild(inputElement);
    inputElement.select(); //选中文本
    document.execCommand("copy"); //执行浏览器复制命令
    inputElement.remove();
    resolve();
  });
}
// 降序
export function desc(obj) {
  return function(a, b) {
    var value1 = a[obj];
    var value2 = b[obj];
    return value2 - value1;
  };
}
// 升序
export function asc(obj) {
  return function(a, b) {
    var value1 = a[obj];
    var value2 = b[obj];
    return value1 - value2;
  };
}
export function numSort(type, array, obj) {
  if (type === "desc") {
    return array.sort(desc(obj));
  } else {
    return array.sort(asc(obj));
  }
}

// 倒计时
export function leftTimer(leftTime) {
  // eslint-disable-next-line no-unused-vars
  var days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10); //计算剩余的天数
  var hours = parseInt((leftTime / 1000 / 60 / 60) % 24, 10); //计算剩余的小时
  var minutes = parseInt((leftTime / 1000 / 60) % 60, 10); //计算剩余的分钟
  var seconds = parseInt((leftTime / 1000) % 60, 10); //计算剩余的秒数
  days = checkTime(days);
  hours = checkTime(hours);
  minutes = checkTime(minutes);
  seconds = checkTime(seconds);
  return hours + ":" + minutes + ":" + seconds;
}
export function checkTime(i) {
  //将0-9的数字前面加上0，例1变为01
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

// 计算盈亏
//如果是1做多 判断当前价大于买入价 盈利交易额*盈利百分比  否则亏损交易额
//如果是2做空 判断当前价小于买入价 盈利交易额*盈利百分比  否则亏损交易额
export function countWinlose(nowprice, type, buyprice, deal, ratio) {
  let num = 0;
  if (type === 1) {
    if (nowprice > buyprice) {
      num = "+" + rideFix(deal, ratio);
    } else {
      num = "-" + deal;
    }
  } else {
    if (nowprice < buyprice) {
      num = "+" + rideFix(deal, ratio);
    } else {
      num = "-" + deal;
    }
  }
  return num;
}
//股票计算盈亏
//如果是1做多 判断当前价大于买入价 盈利交易额*盈利百分比  否则亏损交易额*亏损百分比
//如果是2做空 判断当前价小于买入价 盈利交易额*盈利百分比  否则亏损交易额*亏损百分比
export function stockCountWinlose(
  nowprice,
  type,
  buyprice,
  deal,
  ratio,
  loss_ratio
) {
  let num = 0;
  if (type === 1) {
    if (nowprice > buyprice) {
      num = "+" + rideFix(deal, ratio);
    } else {
      num = "-" + rideFix(deal, loss_ratio);
    }
  } else {
    if (nowprice < buyprice) {
      num = "+" + rideFix(deal, ratio);
    } else {
      num = "-" + rideFix(deal, loss_ratio);
    }
  }
  return num;
}

// 保留n位小数
export function decimalDigitsInputFilter(value, precision) {
  let val = value?.toString();
  // var regExp = /^(-)*(\d+)\.(\d{2}).*$/;
  var regExp = new RegExp("^(-)*(\\d+)\\.(\\d{" + precision + "}).*$");
  if (precision === 0) {
    return val?.replace(regExp, "$1$2");
  }
  return val?.replace(regExp, "$1$2.$3");
}
// 保留n位小数向上取整
export function floorDigitsInputFilter(d, prex) {
  console.log("floorDigitsInputFilter", d);
  const powerOfTen = Math.pow(10, prex);
  return Math.ceil(d * powerOfTen) / powerOfTen;
  // if (parseInt(d) == d) return d;
  // let len = 1;
  // let i = 0;
  // while (parseInt(d) != d) {
  //   d = d * 10;
  //   len *= 10;
  //   i++;
  // }
  // let last = Math.abs(i <= prex ? 0 : d % Math.pow(10, i - prex));
  // let d1 = d - last;
  // if (last > 0) {
  //   d1 = d1 + Math.pow(10, i - prex);
  // }
  // d1 = d1 / len;
  // return d1;
}
// 保留n位小数不带0
export function formatDecimal(num, decimal) {
  let decimalv = decimal ? decimal : 2;
  if (typeof num === "number" && !isNaN(num)) {
    num = num?.toString();
    let index = num?.indexOf(".");
    if (index !== -1) {
      num = num?.substring(0, decimalv + index + 1);
    } else {
      num = num?.substring(0);
    }
    return parseFloat(num);
  } else {
    return null;
  }
}
// 按首字母排序
export function firstletterSort(arr, eachName) {
  if (eachName) {
    return arr.sort(function(param1, param2) {
      return param1[eachName].localeCompare(param2[eachName], "zh");
    });
  } else {
    return arr.sort(function(param1, param2) {
      return param1.localeCompare(param2, "zh");
    });
  }
}

// 检测是否登录接口拦截
export function LoginInterception(redirectUrl) {
  return new Promise((resolve, reject) => {
    if (store.getters.token) {
      resolve(true);
    } else {
      if (store.state.exchange.redirectUrl !== redirectUrl) {
        store.commit("exchange/SET_REDIRECT_URL", redirectUrl);
      }
      ElMessageBox.confirm(
        VueI18n.global.t("formTip.loginExpired"),
        VueI18n.global.t("tips.tips"),
        {
          confirmButtonText: VueI18n.global.t("confirm"),
          cancelButtonText: VueI18n.global.t("cancel"),
          type: "warning",
          customClass: "msg-box",
        }
      )
        .then(() => {
          store.commit("exchange/SET_RELOAD", true);
          router.replace(`/login?redirect=${redirectUrl}`);
        })
        .catch(() => {
          store.commit("exchange/SET_RELOAD", false);
          store.dispatch("user/logout").then(() => {});
          reject(false);
        });
    }
  });
}

export function isMobile() {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  if (flag === null) {
    return false;
  } else {
    return true;
  }
}

// 过滤数字
export function toBeNumber(value) {
  value = value?.replace(/[\u4e00-\u9fa5]+/g, ""); //验证非汉字
  value = value?.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
  value = value?.replace(/^\./g, ""); //验证第一个字符是数字而不是
  value = value
    ?.replace(".", "$#$")
    .replace(/\./g, "")
    .replace("$#$", "."); //只保留第一个小数点, 清除多余的
  return value;
}

// 数字转成文字
export function numList() {
  var chnNumChar = [
    VueI18n.global.t("nums.zero"),
    VueI18n.global.t("nums.one"),
    VueI18n.global.t("nums.two"),
    VueI18n.global.t("nums.three"),
    VueI18n.global.t("nums.four"),
    VueI18n.global.t("nums.fives"),
    VueI18n.global.t("nums.six"),
    VueI18n.global.t("nums.seven"),
    VueI18n.global.t("nums.eight"),
    VueI18n.global.t("nums.nine"),
  ];
  var chnUnitChar = ["", VueI18n.global.t("nums.ten"), "百", "千"];
  var chnUnitSection = ["", "万", "亿", "万亿", "亿亿"];
  return { chnNumChar, chnUnitChar, chnUnitSection };
}
function SectionToChinese(section) {
  const { chnNumChar, chnUnitChar } = numList();
  var strIns = "";
  var chnStr = "";
  var unitPos = 0;
  var zero = true;
  let num = section;
  while (num > 0) {
    var v = num % 10;
    if (v === 0) {
      if (!zero) {
        zero = true;
        chnStr = chnNumChar[v] + chnStr;
      }
    } else {
      zero = false;
      strIns = chnNumChar[v];
      if (section >= 10 && section <= 19) {
        strIns = chnUnitChar[unitPos];
      } else {
        strIns += chnUnitChar[unitPos];
      }
      chnStr = strIns + chnStr;
    }
    unitPos++;
    num = Math.floor(num / 10);
  }
  return chnStr;
}
export function NumberToChinese(num) {
  const { chnNumChar, chnUnitSection } = numList();
  var unitPos = 0;
  var strIns = "";
  var chnStr = "";
  var needZero = false;

  if (num === 0) {
    return chnNumChar[0];
  }

  while (num > 0) {
    var section = num % 10000;
    if (needZero) {
      chnStr = chnNumChar[0] + chnStr;
    }
    strIns = SectionToChinese(section);
    strIns += section !== 0 ? chnUnitSection[unitPos] : chnUnitSection[0];
    chnStr = strIns + chnStr;
    needZero = section < 1000 && section > 0;
    num = Math.floor(num / 10000);
    unitPos++;
  }

  return chnStr;
}
//科学计数法转成正常数值
export function transferToNumber(inputNumber) {
  if (isNaN(inputNumber)) {
    return inputNumber;
  }
  inputNumber = "" + inputNumber;
  inputNumber = parseFloat(inputNumber);
  let eformat = inputNumber.toExponential(); // 转换为标准的科学计数法形式（字符串）
  let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/); // 分离出小数值和指数值
  let number = inputNumber.toFixed(
    Math.max(0, (tmpArray[1] || "").length - tmpArray[2])
  );
  return number;
}

//将数值千分位加逗号
export function formatMoney(number, symbol, thousand, decimal) {
  number = number || 0;
  symbol = symbol !== undefined ? symbol : "";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var negative = number < 0 ? "-" : "",
    i = parseInt((number = transferToNumber(Math.abs(+number || 0))), 10) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    symbol +
    negative +
    (j ? i.substr(0, j) + thousand : "") +
    i.substr(j).replace(/(\d)(?=(?:\d{3})+$)/g, "$1" + thousand) +
    (Math.abs(number - i) > 0 ? decimal + reduceFix(number, i).slice(2) : "")
  );
}

/**
 * 字符串隐藏部分信息
 * @param {*} str 字符串
 * @param {*} startIndex 替换起始位置默认第4位
 * @param {*} lenth 替换长度默认长度4
 */
export function hidePartStr(str, startIndex = 2, lenth = 4) {
  const rep = new RegExp(`^(.{${startIndex}})(.{${lenth}})(.*)$`);
  return str.replace(rep, "$1****$3");
}

/**
 * 计算股票收益
 */
export function getStockIncome(avg_price, nowprice, total_amount) {
  const inconme = rideFix(
    reduceFix(parseFloat(nowprice), parseFloat(avg_price)),
    parseFloat(total_amount)
  );
  return inconme;
}

/**
 * 计算股票收益率
 */
export function getStockIncomeRate(avg_price, nowprice, total_amount) {
  const inconme = getStockIncome(avg_price, nowprice, total_amount);
  const inconmeRate = exceptFix(inconme, rideFix(avg_price, total_amount));
  return rideFix(inconmeRate, 100);
}

/**
 * 判断某个原生DOM元素是否不在屏幕可见区内
 * @param {*} el 原生DOM元素
 */
export function isElementNotInViewport(el, parant) {
  const viewHeight = parant?.scrollTop;
  let rect = el.getBoundingClientRect();
  console.log(rect, viewHeight);
  return rect.top >= 0 && rect.height >= viewHeight;
}

/**
 * 获取字符串的前几个字符
 */
export function getFirstIcon(val, length) {
  if (val) {
    const outputString = val.slice(0, length);
    return outputString;
  } else {
    return "";
  }
}

//判断当前时间是否在开市时间和闭市时间之间
export function isCurrentTimeInRange(
  open_time,
  close_time,
  time = new Date(),
  timeZone = "us"
) {
  const now = getUsTime(time.getTime(), "YYYY-MM-DD HH:mm:ss", timeZone);
  let nowArr = now.split(" ");
  const [nowHours, nowMinutes, nowSeconds] = nowArr[1].split(":").map(Number);
  const currentTime = nowHours * 3600 + nowMinutes * 60 + nowSeconds;

  // 转换开始时间和结束时间为秒数
  const [startHours, startMinutes, startSeconds] = open_time
    .split(":")
    .map(Number);
  const startTimeInSeconds =
    startHours * 3600 + startMinutes * 60 + startSeconds;

  const [endHours, endMinutes, endSeconds] = close_time.split(":").map(Number);
  const endTimeInSeconds = endHours * 3600 + endMinutes * 60 + endSeconds;
  // 判断当前时间是否在范围内
  // console.log(
  //   currentTime >= startTimeInSeconds && currentTime <= endTimeInSeconds
  // );
  if (startTimeInSeconds <= endTimeInSeconds) {
    return currentTime >= startTimeInSeconds && currentTime <= endTimeInSeconds;
  } else {
    // 跨天的情况
    return currentTime >= startTimeInSeconds || currentTime <= endTimeInSeconds;
  }
}
