<template>
  <div>
    <div class="userinfo anglebox buble-bg text-left">
      <div
        @click="jump(item.path)"
        v-for="item in state.tabData"
        :key="item.name"
      >
        <div class="flex align-center p-16 buble-line" v-if="item.show">
          <div class="mr-12">
            <img :src="item.url" alt="" class="item-img" />
          </div>
          <div class="user mb-2">
            <div class="flex align-end text-center mb-5">
              <div>{{ item.name }}</div>
              <div
                class="icon-box share-color"
                v-if="item.icon != 'none' && item.icon"
              >
                {{ item.icon === "hot" ? "HOT" : "NEW" }}
              </div>
            </div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: "Personbuble",
  props: {
    wealth_management_status: Boolean,
    defi_ore_status: Boolean,
    currency_subscription_status: Boolean,
    isLoginReg: Boolean,
    stock_status: Boolean,
    defi_ore_logo: String,
    wealth_management_logo: String,
    currency_subscription_logo: String,
    wealth_management_type: Number,
    stock_subscription_logo: String,
    stock_subscription_status: Boolean,
    pledge_borrowing_status: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const state = reactive({
      tabData: [
        {
          name: t("header.DefiMining"),
          path: "/defiFinancial",
          show: props.defi_ore_status,
          desc: t("newTheme.defiMining"),
          url: require("@/assets/theme1/header/defi.png"),
          icon: props.defi_ore_logo,
        },
        {
          name:
            props.wealth_management_type === 1
              ? t("header.wealthTreasure")
              : t("hosting.robotHosting"),
          path: "/wealthFinancial",
          show: props.wealth_management_status,
          desc: t("newTheme.financial"),
          url: require("@/assets/theme1/header/weath.png"),
          icon: props.wealth_management_logo,
        },
        // {
        //   name: t("foreign.forexTrad"),
        //   path: "/foreignExchange",
        //   show: foreign_exchange.value,
        //   desc: t("stock.headerStockTip"),
        //   url: require("@/assets/theme1/header/stock.png"),
        //   icon: props.wealth_management_logo,
        // },
        {
          name: t("newCoin.subscription"),
          path: "/sgdSubscription",
          show: props.currency_subscription_status,
          desc: t("newCoin.lockNewCoin") + "," + t("newCoin.redeemProfits"),
          url: require("@/assets/theme1/header/sgd.png"),
          icon: props.currency_subscription_logo,
        },
        {
          name: t("newCoin.stockSubscription"),
          path: "/newIPO",
          show: props.stock_subscription_status,
          desc: t("newCoin.lockNewStock") + "," + t("newCoin.redeemProfits"),
          url: require("@/assets/theme1/header/newstock.png"),
          icon: props.stock_subscription_logo,
        },
        {
          name: t("loan.loan"),
          path: "/loan",
          show: props.pledge_borrowing_status,
          desc: t("loan.stakingDigitalCurrency") + "," + t("loan.meetNeeds"),
          url: require("@/assets/theme1/header/exchange.png"),
          icon: null,
        },
      ],
    });
    const $router = useRouter();
    const jump = (path) => {
      $router.push(path);
    };
    return {
      state,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.p-10 {
  padding-top: 10px;
}
// 个人中心弹窗
.userinfo {
  width: 300px;
  position: relative;
  // border-radius: 8px;
  // padding: 0 10px;
}
.user {
  // height: 44px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
}
.p-16 {
  padding: 16px;
}
.desc {
  font-size: 10px;
  line-height: 14px;
  color: #ffff;
  opacity: 0.5;
  font-weight: normal;
}
.name {
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-inline-start: 6px;
}
.center-model {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.mb-8 {
  margin-bottom: 8px;
}
.logout {
  height: 37px;
  line-height: 37px;
  color: #cf2f43;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mr-12 {
  margin-inline-end: 12px;
}
.item-img {
  display: block;
  width: 30px;
  height: 30px;
}
.icon-box {
  font-size: 8px;
  background-color: #e76549;
  border-radius: 2px;
  width: 28px;
  // line-height: 10px;
  margin-inline-start: 4px;
}
</style>
