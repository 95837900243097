const etfRouter = [
  {
    path: "/:lang/etf",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "ETF",
    component: () => import("@/views/etf/etf.vue"),
  },
  {
    path: "/:lang/etfLever",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "ETFLever",
    component: () => import("@/views/etf/etfLever.vue"),
  },
  {
    path: "/:lang/etfSecond",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "ETFSecond",
    component: () => import("@/views/etf/etfSecond.vue"),
  },
];
export default etfRouter;
