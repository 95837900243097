import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
export default function UseSystemSetting() {
  const $store = useStore();
  // 法币交易开关
  // 法币交易开关
  const fiat_currency_transaction_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const fiat_currency_transaction_status = systemInfo?.find((item) => {
      return item.key === "fiat_currency_transaction_status";
    });
    return fiat_currency_transaction_status?.value || false;
  });

  const stock_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const stock_status = systemInfo?.find((item) => {
      return item.key === "stock_status";
    });
    return stock_status?.value || false;
  });
  // 1-链接 2-客服 3-法币页 4-三方联系5、三方法币接口
  const easy_buy_type = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const easy_buy_type = systemInfo?.find((item) => {
      return item.key === "easy_buy_type";
    });
    return easy_buy_type?.value || 1;
  });
  const easy_buy_url = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const easy_buy_url = systemInfo?.find((item) => {
      return item.key === "easy_buy_url";
    });
    return easy_buy_url?.value || "http://www.baidu.com";
  });
  const uid_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const uid_status = systemInfo?.find((item) => {
      return item.key === "uid_status";
    });
    return uid_status?.value || false;
  });
  // 理财热门、最新图标
  const defi_ore_logo = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const defi_ore_logo = systemInfo?.find((item) => {
      return item.key === "defi_ore_logo";
    });
    return defi_ore_logo?.value || "none";
  });
  // 挖坑热门、最新图标
  const wealth_management_logo = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wealth_management_logo = systemInfo?.find((item) => {
      return item.key === "wealth_management_logo";
    });
    return wealth_management_logo?.value || "none";
  });
  // 币币交易开关
  const currency_transaction_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const currency_transaction_status = systemInfo?.find((item) => {
      return item.key === "currency_transaction_status";
    });
    return currency_transaction_status?.value || false;
  });
  // 合约交易开关
  const lever_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const lever_status = systemInfo?.find((item) => {
      return item.key === "lever_status";
    });
    return lever_status?.value || false;
  });
  // 秒合约开关
  const second_lever_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const second_lever_status = systemInfo?.find((item) => {
      return item.key === "second_lever_status";
    });
    return second_lever_status?.value || false;
  });
  // 信誉分开关
  const credit_score_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const credit_score_status = systemInfo?.find((item) => {
      return item.key === "credit_score_status";
    });
    return credit_score_status?.value || false;
  });
  // 闪兑开关
  const flashAgainstSwitch = computed(() => {
    return $store.state.user.flashAgainstSwitch;
  });
  // 挖矿
  const defi_ore_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const defi_ore_status = systemInfo?.find((item) => {
      return item.key === "defi_ore_status";
    });
    return defi_ore_status?.value || false;
  });
  // 理财
  const wealth_management_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wealth_management_status = systemInfo?.find((item) => {
      return item.key === "wealth_management_status";
    });
    return wealth_management_status?.value || false;
  });
  // 新币申购开关
  const currency_subscription_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const currency_subscription_status = systemInfo?.find((item) => {
      return item.key === "currency_subscription_status";
    });
    return currency_subscription_status?.value || false;
  });
  // 新币申购标识
  const currency_subscription_logo = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const currency_subscription_logo = systemInfo?.find((item) => {
      return item.key === "currency_subscription_logo";
    });
    return currency_subscription_logo?.value || false;
  });
  // nft分类开关
  const nft_status = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const nft_status = systemInfo?.find((item) => {
      return item.key === "nft_status";
    });
    return nft_status?.value || false;
  });
  // nft入口开关
  const nft_entrance = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const nft_entrance = systemInfo?.find((item) => {
      return item.key === "nft_entrance";
    });
    return nft_entrance?.value || false;
  });
  // 世界杯开关
  const world_cup = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "world_cup";
    });
    return temp?.value || false;
  });
  // 理财和机器人托管切换(1-理财，2-机器人托管)
  const wealth_management_type = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "wealth_management_type";
    });
    return temp?.value || 1;
  });
  // 元宇宙开关
  const meta_switch = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "meta_switch";
    });
    return temp?.value || false;
  });
  // 上链开关
  const wallet_status = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "wallet_status";
    });
    return temp?.value || false;
  });
  //  充值提交
  const recharge_manual = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "recharge_manual";
    });
    return temp?.value || false;
  });
  // 转账数量
  const recharge_manual_amount = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "recharge_manual_amount";
    });
    return temp?.value || false;
  });
  // hash地址/发送地址
  const recharge_manual_hash = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "recharge_manual_hash";
    });
    return temp?.value || false;
  });
  // 转账截图
  const recharge_manual_transaction = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "recharge_manual_transaction";
    });
    return temp?.value || false;
  });
  //外汇
  const foreign_exchange = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "foreign_exchange";
    });
    return temp?.value || false;
  });
  //手持照片(认证)
  const holding_id_card = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "holding_id_card";
    });
    return temp?.value || false;
  });
  //外汇账号
  const wallet_forex = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "wallet_forex";
    });
    return temp?.value || false;
  });
  //c2c提现
  const c2c_withdraw = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "c2c_withdraw";
    });
    return temp?.value || false;
  });
  //记住密码
  const remember_password = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "remember_password";
    });
    return temp?.value || false;
  });
  //默认钱包
  const default_wallet = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "default_wallet";
    });
    return temp?.value || false;
  });
  //默认语言
  const default_language_tag = computed(() => {
    if (
      $store.state.user?.systemInfo &&
      $store.state.user?.systemInfo?.length > 0
    ) {
      let temp = $store.state.user?.systemInfo?.find((item) => {
        return item.key === "default_language_tag";
      });
      return temp?.value || "en";
    }
    return "en";
  });
  // // 虚拟账户
  // const virtual_wallet = computed(() => {
  //   let temp =
  //     ($store.state.user?.virtual_wallet || false) &&
  //     ($store.state.user.token ? true : false);
  //   // console.log("是否模拟账户11", temp);
  //   return temp || false;
  // });
  //平台内容开启[帮助中心,公告中心,资讯中心](true-开启，false-关闭)
  const platform_content_management = computed(() => {
    let temp = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "platform_content_management";
    });
    return temp?.value || [false, false, false];
  });
  const pledge_borrowing_status = computed(() => {
    let temp = $store.state.user?.systemInfo.find((item) => {
      return item.key === "pledge_borrowing_status";
    });
    return temp?.value || false;
  });
  // 虚拟账户
  const virtual_wallet = computed(() => {
    let status = $store.state.user?.systemInfo?.find((item) => {
      return item.key === "virtual_wallet";
    });
    // if (!status?.value) {
    //   $store.dispatch("user/changeVirtualWallet", false);
    // }
    let temp =
      ($store.state.user?.virtual_wallet || false) &&
      ($store.state.user.token ? true : false) &&
      (status?.value || false);
    return temp || false;
  });

  const service_url = computed(() => {
    let temp = $store.state.user?.systemInfo.find((item) => {
      return item.key === "service_url";
    });
    return temp?.value || "";
  });
  const about_us_pc = computed(() => {
    let temp = $store.state.user?.systemInfo.find((item) => {
      return item.key === "about_us_pc";
    });
    return temp?.value || "";
  });
  const legal_countdown = computed(() => {
    let temp = $store.state.user?.systemInfo.find((item) => {
      return item.key === "legal_countdown";
    });
    return temp?.value || false;
  });

  // ETF现货交易开关
  const etf_spot = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const etf_spot = systemInfo?.find((item) => {
      return item.key === "etf_spot";
    });
    return etf_spot?.value || false;
  });
  // ETF合约开关
  const etf_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const etf_lever = systemInfo?.find((item) => {
      return item.key === "etf_lever";
    });
    return etf_lever?.value || false;
  });
  // ETF期权开关
  const etf_second_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const etf_second_lever = systemInfo?.find((item) => {
      return item.key === "etf_second_lever";
    });
    return etf_second_lever?.value || false;
  });
  // 大宗现货交易开关
  const commodity_spot = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const commodity_spot = systemInfo?.find((item) => {
      return item.key === "commodity_spot";
    });
    return commodity_spot?.value || false;
  });
  // 大宗合约开关
  const commodity_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const commodity_lever = systemInfo?.find((item) => {
      return item.key === "commodity_lever";
    });
    return commodity_lever?.value || false;
  });
  // 大宗期权开关
  const commodity_second_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const commodity_second_lever = systemInfo?.find((item) => {
      return item.key === "commodity_second_lever";
    });
    return commodity_second_lever?.value || false;
  });

  // ETF现货交易钱包开关
  const wallet_etf = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wallet_etf = systemInfo?.find((item) => {
      return item.key === "wallet_etf";
    });
    return wallet_etf?.value || false;
  });
  // ETF合约钱包开关
  const wallet_etf_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wallet_etf_lever = systemInfo?.find((item) => {
      return item.key === "wallet_etf_lever";
    });
    return wallet_etf_lever?.value || false;
  });
  // ETF期权钱包开关
  const wallet_etf_second_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wallet_etf_second_lever = systemInfo?.find((item) => {
      return item.key === "wallet_etf_second_lever";
    });
    return wallet_etf_second_lever?.value || false;
  });
  // 大宗现货交易钱包开关
  const wallet_commodity = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wallet_commodity = systemInfo?.find((item) => {
      return item.key === "wallet_commodity";
    });
    return wallet_commodity?.value || false;
  });
  // 大宗合约钱包开关
  const wallet_commodity_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wallet_commodity_lever = systemInfo?.find((item) => {
      return item.key === "wallet_commodity_lever";
    });
    return wallet_commodity_lever?.value || false;
  });
  // 大宗期权钱包开关
  const wallet_commodity_second_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wallet_commodity_second_lever = systemInfo?.find((item) => {
      return item.key === "wallet_commodity_second_lever";
    });
    return wallet_commodity_second_lever?.value || false;
  });
  // 外汇期权开关
  const forex_second_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const forex_second_lever = systemInfo?.find((item) => {
      return item.key === "forex_second_lever";
    });
    return forex_second_lever?.value || false;
  });
  // 外汇期权钱包开关
  const wallet_forex_second_lever = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    const wallet_forex_second_lever = systemInfo?.find((item) => {
      return item.key === "wallet_forex_second_lever";
    });
    return wallet_forex_second_lever?.value || false;
  });
  return {
    fiat_currency_transaction_status,
    stock_status,
    easy_buy_type,
    easy_buy_url,
    uid_status,
    wealth_management_logo,
    defi_ore_logo,
    currency_transaction_status,
    lever_status,
    second_lever_status,
    flashAgainstSwitch,
    defi_ore_status,
    wealth_management_status,
    currency_subscription_status,
    currency_subscription_logo,
    nft_status,
    nft_entrance,
    world_cup,
    wealth_management_type,
    meta_switch,
    wallet_status,
    recharge_manual,
    recharge_manual_amount,
    recharge_manual_hash,
    recharge_manual_transaction,
    virtual_wallet,
    foreign_exchange,
    wallet_forex,
    default_wallet,
    default_language_tag,
    holding_id_card,
    remember_password,
    platform_content_management,
    pledge_borrowing_status,
    credit_score_status,
    service_url,
    c2c_withdraw,
    about_us_pc,
    legal_countdown,
    wallet_forex_second_lever,
    wallet_etf,
    wallet_etf_lever,
    wallet_etf_second_lever,
    wallet_commodity,
    wallet_commodity_lever,
    wallet_commodity_second_lever,
    forex_second_lever,
    etf_spot,
    etf_lever,
    etf_second_lever,
    commodity_spot,
    commodity_lever,
    commodity_second_lever,
  };
}
