import Cookies from "js-cookie";

const TokenKey = "Newpc-Token";
const TokenTimeKey = "Newpc-Token-Time";
const RefreshTokenKey = "Newpc-Refresh-Token";
const InviteCodeKey = "Newpc-Invite-Code";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 7 });
}

export function setTokenTime(time) {
  return Cookies.set(TokenTimeKey, time);
}

export function getTokenTime() {
  return Cookies.get(TokenTimeKey);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function removeTokenTime() {
  return Cookies.remove(TokenTimeKey);
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey);
}

export function setRefreshToken(refresh_token) {
  return Cookies.set(RefreshTokenKey, refresh_token, { expires: 30 });
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey);
}
export function setInviteCode(invite_code) {
  return Cookies.set(InviteCodeKey, invite_code, { expires: 1 });
}

export function getInviteCode() {
  return Cookies.get(InviteCodeKey);
}

export function removeInviteCode() {
  return Cookies.remove(InviteCodeKey);
}
