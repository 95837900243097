<template>
  <div
    :class="
      isLoginReg
        ? 'islogin-bg footer share-bg share-color point'
        : 'footer share-bg share-color point'
    "
  >
    <div class="flex js-ar">
      <div class="footer-left">
        <img
          @click="backhome"
          src="@/assets/theme1/logo.png"
          alt
          v-if="!isLoginReg"
        />
        <img
          @click="backhome"
          src="@/assets/theme1/loginlogo.png"
          alt
          v-if="isLoginReg"
        />
        <p class>{{ $t("footer.title") }}</p>
        <p>{{ $t("footer.desc") }}</p>
        <div
          class="lang-box mb-60"
          @click="state.islanguage = !state.islanguage"
          v-clickout="hide"
        >
          {{ state.curentLang }}
          <i class="el-icon-caret-bottom"></i>
          <Language
            @close="hide"
            :isselect="state.islanguage"
            :language="language"
            :isFoot="true"
          />
        </div>
      </div>
      <div class="footer-right flex js-sp">
        <div class="content-box">
          <div class="title color_98">{{ $t("footer.moreContent") }}</div>
          <router-link to="/about" class="text color_98 text-hover">{{
            $t("footer.aboutUs")
          }}</router-link>
          <router-link to="/opinion" class="text color_98 text-hover">{{
            $t("footer.feedback")
          }}</router-link>
          <router-link
            to="/helpcenter"
            v-if="platform_content_management[0]"
            class="text color_98 text-hover"
            >{{ $t("global.helpCenter") }}</router-link
          >
          <router-link
            to="/announce"
            v-if="platform_content_management[1]"
            class="text color_98 text-hover"
            >{{ $t("user.announce") }}</router-link
          >
          <router-link
            to="/news"
            v-if="platform_content_management[2]"
            class="text color_98 text-hover"
            >{{ $t("global.newsList") }}</router-link
          >
        </div>
        <div
          class="content-box"
          v-show="
            state.androidInfo?.source_url ||
              state.iosInfo?.source_url ||
              bibiStatus ||
              leverStatus ||
              secondStatus ||
              stockStatus
          "
        >
          <div class="title color_98">{{ $t("footer.userService") }}</div>
          <router-link
            to="/download"
            class="text color_98 text-hover"
            v-show="state.androidInfo?.source_url || state.iosInfo?.source_url"
            >{{ $t("footer.download") }}</router-link
          >
          <router-link
            to="/rate"
            class="text color_98 text-hover"
            v-show="bibiStatus || leverStatus || secondStatus || stockStatus"
            >{{ $t("footer.rateStandard") }}</router-link
          >
          <!-- <div
            class="text text-hover"
            v-if="state.show && !virtual_wallet"
            @click="jump"
          >
            OTC
          </div> -->
        </div>

        <div class="content-box">
          <div class="title color_98">{{ $t("footer.termsDescription") }}</div>
          <router-link
            v-if="user_protocol"
            to="/agreement?name=user_protocol"
            class="text color_98 text-hover"
            >{{ $t("footer.userProtocol") }}</router-link
          >
          <router-link
            v-if="privacy_policy"
            to="/agreement?name=privacy_policy"
            class="text color_98 text-hover"
            >{{ $t("footer.privacyProtocol") }}</router-link
          >
          <router-link
            v-if="legal_policy"
            to="/agreement?name=legal_policy"
            class="text color_98 text-hover"
            >{{ $t("footer.legalNotices") }}</router-link
          >
        </div>

        <div class="content-box" v-if="isShowWeb || isShowEmail">
          <div class="title color_98">{{ $t("footer.businessContact") }}</div>
          <div class="color_98 text-hover" v-if="isShowWeb">
            {{ $t("footer.officialWebsiteAddress") }}：{{ isShowWeb }}
          </div>
          <div class="color_98 text-hover" v-if="isShowEmail">
            {{ $t("footer.email") }}：{{ isShowEmail }}
          </div>
        </div>
      </div>
    </div>
    <div class="plh-5">
      <div
        class="flex align-start flex-wrap"
        v-if="state.commitList?.length > 0 && commitShow"
      >
        <div class="text-888 text-24 mr-32">community</div>
        <div
          v-for="item in state.commitList"
          :key="item.id"
          v-show="item.platform_show_status"
        >
          <a :href="item.platform_url" target="_blank" class="commiticon">
            <img :src="item.platform_icon" alt="" class=" mr-30" />
          </a>
        </div>
      </div>
    </div>
    <!-- 客服弹窗 -->
    <ServerBox
      @oncancer="state.dialog = false"
      @onconfirm="state.dialog = false"
      :dialog="state.dialog"
    />
    <!-- 法币交易三方 -->
    <ThirdFiatBox
      @oncancer="state.thirDialog = false"
      @onconfirm="state.thirDialog = false"
      :dialog="state.thirDialog"
    ></ThirdFiatBox>
  </div>
</template>

<script>
import Language from "@/components/homeModel/language.vue";
import { reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { getPlatform } from "@/api/system";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jumpChat from "@/commonapi/chat";
import { ElMessage } from "element-plus";
import ServerBox from "@/components/homeModel/serverBox";
import ThirdFiatBox from "@/components/homeModel/thirdFiatBox";
import { getAppRenewal } from "@/api/article";
import useProtocolSetting from "@/commonapi/useProtocolSetting";
import UseSystemSetting from "@/commonapi/useSystemSetting";

export default {
  name: "Footer",
  components: { Language, ServerBox, ThirdFiatBox },
  props: { language: Array, isLoginReg: Boolean },
  setup() {
    // 获取开关状态
    const { virtual_wallet } = UseSystemSetting();
    const { t } = useI18n();
    const $router = useRouter();
    const state = reactive({
      islanguage: false, //选择语言
      curentLang: "",
      link: {},
      show: false,
      commitList: [],
      email: "",
      easy_buy_type: 1,
      service_url: "", //判断客服跳转类型
      dialog: false,
      thirDialog: false,
      androidInfo: {},
      iosInfo: {},
    });
    // 获取开关状态
    const { platform_content_management } = UseSystemSetting();
    const $store = useStore();
    const localary = computed(() => {
      return $store.state.user.localary;
    });
    const systemInfo = computed(() => {
      return $store.state.user.systemInfo;
    });
    const isShowEmail = computed(() => {
      let list = $store.state.user.systemInfo;
      if (list && list.length > 0) {
        list.forEach((ele) => {
          if (ele.key === "business_email") {
            state.email = ele.value;
          }
        });
      }

      return state.email;
    });
    // 获取协议状态
    const {
      user_protocol,
      privacy_policy,
      legal_policy,
    } = useProtocolSetting();
    const isShowWeb = computed(() => {
      let list = $store.state.user.systemInfo;
      if (list && list.length > 0) {
        list.forEach((ele) => {
          if (ele.key === "official_website") {
            state.web = ele.value;
          }
        });
      }

      return state.web;
    });
    watch(
      () => systemInfo.value,
      (val) => {
        if (val) {
          let list = val.filter((item) => {
            return item.key === "fiat_currency_transaction_status";
          });
          state.show = list[0].value;
        }
      }
    );
    watch(
      () => localary.value,
      (val) => {
        console.log(val);
        if (val.length > 0) {
          let flag = val.filter((item) => {
            return item.language_tag === $store.state.user.local;
          });
          if (flag.length > 0) {
            state.curentLang = flag[0].label;
          } else {
            $store.commit("user/SET_LOCAL", val[0].language_tag);
            state.curentLang = val[0].label;
          }
        }
      }
    );
    onMounted(() => {
      getCommit();
      state.link = {
        email: window.VUE_APP_EMAIL,
        web: window.VUE_APP_WEB,
      };
      if (systemInfo.value) {
        let list = systemInfo.value.filter((item) => {
          return item.key === "fiat_currency_transaction_status";
        });
        state.show = list[0].value;
        systemInfo.value.forEach((ele) => {
          if (ele.key == "easy_buy_type") {
            state.easy_buy_type = ele.value;
          }
          if (ele.key == "easy_buy_url") {
            state.url = ele.value;
          }
          if (ele.key === "service_url") {
            const show = ele.value; //online-在线客服页；contact-社交客服页
            state.service_url = show;
            state.dialog = false;
            if (state.service_url === "contact") {
              // 在线客服打开注释
              // zE(function() {
              //   zE.hide();
              // });
            }
          }
        });
      }
      if (localary.value.length > 0) {
        let flag = localary.value.filter((item) => {
          return item.language_tag === $store.state.user.local;
        });
        if (flag.length > 0) {
          state.curentLang = flag[0].label;
        } else {
          $store.commit("user/SET_LOCAL", localary.value[0].language_tag);
          state.curentLang = localary.value[0].label;
        }
      }
    });
    const commitShow = computed(() => {
      let flag = false;
      if (state.commitList.length > 0) {
        state.commitList.forEach((ele) => {
          if (ele.platform_show_status) {
            flag = true;
          }
        });
      }
      return flag;
    });
    //获取社交平台设置
    const getCommit = () => {
      getPlatform().then((res) => {
        state.commitList = res.data;
      });
    };
    const hide = () => {
      state.islanguage = false;
    };
    const bibiStatus = computed(() => {
      let flag = false;
      systemInfo.value?.forEach((ele) => {
        if (ele.key === "currency_transaction_status") {
          flag = ele.value;
        }
      });
      return flag;
    });
    const leverStatus = computed(() => {
      let flag = false;
      systemInfo.value?.forEach((ele) => {
        if (ele.key === "lever_status") {
          flag = ele.value;
        }
      });
      return flag;
    });
    const secondStatus = computed(() => {
      let flag = false;
      systemInfo.value?.forEach((ele) => {
        if (ele.key === "second_lever_status") {
          flag = ele.value;
        }
      });
      return flag;
    });
    const stockStatus = computed(() => {
      let flag = false;
      systemInfo.value?.forEach((ele) => {
        if (ele.key === "stock_status") {
          flag = ele.value;
        }
      });
      return flag;
    });
    const { chatActive } = jumpChat();
    const jump = () => {
      // console.log("Jump", state.easy_buy_type);
      if (state.easy_buy_type === 2) {
        if (state.service_url === "contact") {
          state.dialog = true;
        } else {
          chatActive();
          // 客服代码
          // zE.activate();
        }
      } else if (state.easy_buy_type === 1) {
        const url = state.url;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = url;
        a.click();
      } else if (state.easy_buy_type === 3) {
        if (!state.show) {
          ElMessage.error(t("formTip.noOpen"));
          return false;
        }
        $router.push("/fiat");
      } else if (state.easy_buy_type === 4) {
        state.thirDialog = true;
      }
    };
    const getAppRenewals = (type) => {
      getAppRenewal(type).then((res) => {
        if (type === 1) {
          state.androidInfo = res.data;
        } else {
          state.iosInfo = res.data;
        }
      });
    };
    onMounted(() => {
      getAppRenewals(1);
      getAppRenewals(2);
    });
    return {
      state,
      hide,
      localary,
      systemInfo,
      bibiStatus,
      leverStatus,
      secondStatus,
      stockStatus,
      getCommit,
      commitShow,
      isShowEmail,
      isShowWeb,
      jump,
      chatActive,
      getAppRenewals,
      user_protocol,
      privacy_policy,
      legal_policy,
      platform_content_management,
      virtual_wallet,
    };
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  padding: 70px 90px;
  box-sizing: border-box;
  img {
    width: 98px;
    height: 42px;
  }
}
.islogin-bg {
  background-image: url("../../assets/theme1/loginbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}
.lang-box {
  height: 24px;
  width: 100%;
  line-height: 24px;
  border: 1px solid white;
  text-align: start;
  border-radius: 6px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;
}
.footer-left {
  width: 168px;
  text-align: start;
}
.footer-right {
  width: 70%;
}
.content-box {
  width: 25%;
}
.title {
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 25px;
}
.text {
  font-size: 14px;
  margin-bottom: 16px;
  display: block;
}
.mb-60 {
  margin-bottom: 60px;
}
.mr-32 {
  margin-inline-end: 32px;
}
.mr-30 {
  margin-inline-end: 30px;
}
.commiticon img {
  width: 24px;
  height: 24px;
  display: block;
  margin-bottom: 12px;
}
.plh-5 {
  padding-inline-start: 5%;
}
</style>
