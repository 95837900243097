const foreignExchangeRouter = [
  {
    path: "/:lang/foreignExchange",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "ForeignExchange",
    component: () => import("@/views/foreign-exchange/foreignExchange.vue"),
  },
  {
    path: "/:lang/foreignSecond",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "ForeignSecond",
    component: () => import("@/views/foreign-exchange/foreignSecond.vue"),
  },
];
export default foreignExchangeRouter;
